import React, { Component } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';

import './WorkflowAdd.css';

class WorkflowAdd extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            sequence: '',
            description: '',
            errMsg: ''
        };
    }

    GetName = () => {
        return this.state.name
    }

    GetCommand = () => {
        return this.state.command
    }

    GetDescription = () => {
        return this.state.description
    }

    GetError = () => {
        return this.state.errMsg
    }

    handleNameChange = (e) => {
        this.setState({
            name: e.target.value,
            errMsg: ''
        });
    }

    handleSequenceChange = (e) => {
        this.setState({
            sequence: e.target.value,
            errMsg: ''
        });
    }

    handleDescriptionChange = (e) => {
        this.setState({
            description: e.target.value,
            errMsg: ''
        });
    }

    handleSubmitAddWorkflow = (e) => {
        console.log("handleSubmitAddWorkflow");

        e.preventDefault();
        e.stopPropagation();

        fetch('workflow_add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "name=" + this.state.name + "&sequence=" + this.state.sequence + "&description=" + this.state.description
        })
        .then(res => res.json())
        .then(data =>
        {
            console.log(data);
            if ('true' === data[0].Success)
            {
                this.setState({
                    errMsg: data[0].Msg
                });

                this.props.reloadWorkflows();
                this.props.hideAddWorkflow();
            }
            else
            {
                this.setState({
                    errMsg: data[0].Msg
                });
            }
        })
    }

    render() {
        return (

            <Modal
              show={this.props.showAddWorkflow}
              onHide={this.props.hideAddWorkflow}
              backdrop="static"
              centered
            >
            <Modal.Header closeButton>
                <Modal.Title><center><h1 class="pageTitle">Add Process</h1></center></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div>
                <Form className="WorkflowAddForm" onSubmit={this.handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="frmName">
                            <Form.Label class="WorkflowAddLabel">Name</Form.Label>
                            <Form.Control className="WorkflowAddControl" type="text" placeholder="name" value={this.state.name} onChange={this.handleNameChange}/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="frmSequence">
                            <Form.Label class="WorkflowAddLabel">Sequence</Form.Label>
                            <Form.Control className="WorkflowAddControl" type="text" placeholder="sequence" value={this.state.sequence} onChange={this.handleSequenceChange}/>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="frmDescription">
                            <Form.Label class="WorkflowAddLabel">Description</Form.Label>
                            <Form.Control className="WorkflowAddControl" type="text" placeholder="Description" value={this.state.description} onChange={this.handleDescriptionChange} />
                        </Form.Group>
                    </Form.Row>
                </Form>
            </div>
            </Modal.Body>

            <Modal.Footer>
                <span className="userAddErr">{this.state.errMsg}</span>
                <Button onClick={this.handleSubmitAddWorkflow}>Add</Button>
                <Button onClick={this.props.hideAddWorkflow}>Close</Button>
            </Modal.Footer>
            </Modal>
        )
    }
}

export default WorkflowAdd;
