const SystemUtils = {
    GetSizeString: function(size) {
        var ret = "";
        var val = 0;

        if (size > (1000 * 1000 * 1000))
        {
            val = size / (1000 * 1000 * 1000);
            ret = " Gb";
        }
        else if (size > (1000 * 1000))
        {
            val = size / (1000 * 1000);
            ret = " Mb";
        }
        else if (size > 1000)
        {
            val = size / (1000);
            ret = " Kb";
        }
        else
        {
            val = size;
            ret = " bytes";
        }

        if (size > 0)
            ret = val.toFixed(2) + ret;
        else
            ret = "0 bytes";

        return ret;
    }
}

export default SystemUtils;

