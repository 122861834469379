import React, { Component } from 'react';
import { Form, Jumbotron, ProgressBar } from 'react-bootstrap';

import { v4 as uuidv4 } from "uuid";

import './CloudUpload.css';

class CloudUpload extends Component {

    constructor(props) {
        super(props);

        this.state = {
            attemptCancel: false,
            chunkSize: 1048576 * 2, //its 3MB, increase the number measure in mb
            showUploadFile: false,
            showProgress: true,
            counter: 0,
            activeFilename: '',
            filesToUpload: {},
            activateUploadindex: 0,
            progress: 0,
            progressText: "",
            fileGuid: "",
            fileSize: 0,
            chunkCount: 0,
            recordingAddFile: this.props.recordingAddFile //only applies to existing recordings which are having files uploaded
        }
    }

    componentDidMount() {
        fetch('system_stats')
        .then(res => res.json())
        .then((data) => {
            this.setState({
                totalSpace: data[0].totalSpace,
                freeSpace: data[0].freeSpace
            });
        })
        .catch(console.log)

        fetch('cloud_stats')
        .then(res => res.json())
        .then((data) => {

            this.setState({
                numClouds: data[0].numClouds
            });
        })
        .catch(console.log)
    }

    componentDidUpdate() {
        //console.log("componentDidUpdate");
    }

    Close = () => {
        //console.log("Close!\n");
        //console.log(this.state.counter);
        if (this.state.counter > 0)
        {
            this.setState({ attemptCancel: true });
        }
    }

    IsTransferring = () => {
        return (this.state.counter > 0);
    }

    getFileContext = (e) => {
        //console.log("get file context");
        //console.log(e.target);
        //console.log(e.target.files);

        const fileList = e.target.files;
        const firstFile = e.target.files[0];
        const _totalCount = firstFile.size % this.state.chunkSize === 0 ? firstFile.size / this.state.chunkSize : Math.floor(firstFile.size / this.state.chunkSize) + 1; // Total count of chunks will have been upload to finish the file
        const _fileID = uuidv4() + "." + firstFile.name.split(".").pop();

        this.setState({
            activeFilename: firstFile.name,
            fileSize: firstFile.size,
            chunkCount: _totalCount,
            filesToUpload: fileList,
            fileGuid: _fileID
        });

        if (firstFile.size > 0)
        {
            this.setState({ showProgress: true });
            this.fileUpload(_totalCount, firstFile, _fileID);
        }
    }

    fileUpload = (chunkCount, _file, fileId) => {

        fetch('cloud_start_upload', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "filename=" + _file.name  + "&filesize=" + _file.size + "&recording=" + this.state.recordingAddFile
        })
        .then(res => res.json())
        .then((data) => {

            if (data.success)
            {
                this.setState({
                    recordingAddFile: data.recording
                });
                //console.log("Recording is " + data.recording);
                this.uploadChunk( _file);
            }
            else
            {
                this.setState({
                    attemptCancel: false,
                    showProgress: true,
                    counter: 0,
                    filesToUpload: {},
                    activateUploadindex: 0,
                    progress: 0,
                    progressText: "Upload Failed",
                    fileGuid: "",
                    fileSize: 0,
                    chunkCount: 0
                });
            }

        })
    }

    uploadChunk = (file) => {

        if (this.state.attemptCancel)
        {
            fetch('cloud_cancel_upload', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: "recording=" + this.state.recordingAddFile
            })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    attemptCancel: false,
                    showProgress: true,
                    counter: 0,
                    filesToUpload: {},
                    activateUploadindex: 0,
                    progress: 0,
                    progressText: "Upload Cancelling",
                    fileGuid: "",
                    fileSize: 0,
                    chunkCount: 0
                });
            })
        }
        else
        {
            const startByte = this.state.counter * this.state.chunkSize;
            const endByte = ((startByte + this.state.chunkSize) > file.size) ? file.size : (startByte + this.state.chunkSize);
            const progress = 100.0 * endByte / file.size;

            //console.log("upload chunk");
            //console.log(this.state.counter);
            //console.log(startByte);
            //console.log(endByte);
            //console.log(file.size);

            if (endByte <= file.size)
            {
                const formData = new FormData();
                var chunk = file.slice(startByte, endByte);
                const newCount = this.state.counter + 1;
                this.setState({ counter: newCount, progress: progress, progressText: this.state.activeFilename + " upload is " + this.state.progress.toFixed(1) + "%" });

                formData.append('file', chunk);
                //formData.append('recording', this.state.recordingAddFile); //this does not need to be added to the upload chunk call

                try {

                    fetch('cloud_upload', {
                        method: 'POST',
                        body: formData
                        //body: "id=" + counter + "&fileName=" + fileGuid + "&chunk=" + formData
                    })
                    .then(res => res.json())
                    .then((data) => {
                        //this.setState({ users: data });
                        //console.log('cloud_upload_complete');
                        if (endByte < file.size)
                        {
                            this.uploadChunk(file);
                        }
                        else
                        {
                            //console.log("First file has uploaded succesfully!");
                            fetch('cloud_stop_upload', {
                                method: 'POST',
                                body: "recording=" + this.state.recordingAddFile
                            })
                            .then(res => res.json())
                            .then((data) => {

                                //console.log(this.state.activateUploadindex);
                                //console.log(this.state.filesToUpload.length);
                                //console.log(this.state.filesToUpload);
                                const activeIndex = this.state.activateUploadindex;

                                if ((activeIndex+1) < this.state.filesToUpload.length)
                                {
                                    const nextFile = this.state.filesToUpload[1 + this.state.activateUploadindex];
                                    const _totalCount = nextFile.size % this.state.chunkSize === 0 ? nextFile.size / this.state.chunkSize : Math.floor(nextFile.size / this.state.chunkSize) + 1; // Total count of chunks will have been upload to finish the file
                                    const _fileID = uuidv4() + "." + nextFile.name.split(".").pop();

                                    this.setState({
                                        activeFilename: nextFile.name,
                                        fileSize: nextFile.size,
                                        counter: 0,
                                        chunkCount: _totalCount,
                                        fileGuid: _fileID,
                                        activateUploadindex: 1 + activeIndex
                                    });

                                    if (nextFile.size > 0)
                                    {
                                        this.setState({ showProgress: true });
                                        this.fileUpload(_totalCount, nextFile, _fileID);
                                    }
                                }
                                else
                                {
                                    this.setState({
                                        showProgress: true,
                                        counter: newCount,
                                        filesToUpload: {},
                                        activateUploadindex: 0,
                                        progress: 0,
                                        progressText: "Upload Cancelled",
                                        fileGuid: "",
                                        fileSize: 0,
                                        chunkCount: 0
                                    });

                                    this.props.handleCloseUpload();
                                }
                            })
                        }
                    })
                }
                catch (error)
                {
                    console.log("error", error);
                }
            }
        }

    }

    render() {
        //const progressInstance = <ProgressBar className="prgUpload" animated now={this.state.progress} label={`${this.state.progress.toFixed(1)}%`} />;

        const blackStyle = {
            color: "black",
        };

        const progressInstance = <ProgressBar className="prgUpload" animated now={this.state.progress} label={`${this.state.progressText}%`} style={blackStyle} />;

        return (<Jumbotron className="jmbUpload">
                <Form>
                    <Form.Group>
                    <Form.File
                        id="frmFile"
                        multiple
                        style={blackStyle}
                        onChange={this.getFileContext}
                        label={this.state.progressText}
                        accept=".ply, .las, .mp4, .txt | pointcloud/*"
                    />
                    </Form.Group>
                    <Form.Group style={{ display: this.state.showProgress ? "block" : "none" }}>
                    {progressInstance}
                    </Form.Group>
                    <label>{this.state.recordingName}</label>
                </Form>

                </Jumbotron>)
    }
}

export default CloudUpload;
