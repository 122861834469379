import React, { Component } from 'react';
import { Button, Jumbotron, Modal, Pagination } from 'react-bootstrap';

import CategoryList from './CategoryList.js';
import CategoryAdd from './CategoryAdd.js';
import CategoryChange from './CategoryChange.js';

import './SettingsPage.css';

class SettingsPage extends Component {

    constructor(props) {
        super(props);

        let category = {
            categoryId: -1,
            title: "",
            colour: "",
            baselineRecording: ""
        }

        this.state = {
            numCategories: 1,
            showAddCategory: false,
            updateCategoryVisible: false,
            updateCategory: category,
            errMsg: '',
            startIndex: 0,
            pageId: 1,
            pageSize: 10
        };

        this.frmAddCategory = React.createRef();
    }

    componentDidMount() {
        fetch('category_info')
        .then(res => res.json())
        .then((data) => {

            this.setState({
                numCategories: data[0].numCategories
            });
        })
        .catch(console.log)
    }

    reloadCategories = () => {
        fetch('category_info')
        .then(res => res.json())
        .then((data) => {

            this.setState({
                numCategories: data[0].numCategories
            });
        })
        .catch(console.log)

        this.refs.categorylist.reloadList();
    }

    renderPagination = () => {
        let numPages = 1 + this.state.numCategories / this.state.pageSize;
        let items = [];
        let active = this.state.pageId;

        for (let number = 1; number <=numPages; number++)
        {
            items.push(
                <Pagination.Item className="pageEvents" key={number} active={number === active}>
                {number}
                </Pagination.Item>,
            );
        }

        return (
            <Pagination onClick={this.loadPage}>{items}</Pagination>
        )
    }

    loadPage = e => {
        if (typeof e.target.text !== "undefined")
        {
            let newPageId = parseInt(e.target.text);
            let newStartIndex = (newPageId-1) * this.state.pageSize;

            this.setState({
                pageId: newPageId,
                startIndex: newStartIndex
            });

            this.refs.categorylist.reloadList(newStartIndex, this.state.pageSize);
        }
    }

    reloadSettings = () => {
        this.refs.categorylist.reloadList(this.state.pageId, this.state.pageSize);
    }

    handleAddCategory = () => {
        this.setState({
            showAddCategory: true,
            errMsg: ''
        });
    }

    handleCloseCategory = () => {
        this.setState({ showAddCategory: false });
    }

    handleSubmitCategory = () => {
        fetch('category_add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "title=" + this.frmAddCategory.current.GetTitle() + "&colour=" + this.frmAddCategory.current.GetColour()
        })
        .then(res => res.json())
        .then(data =>
        {
            if ('true' === data[0].add)
            {
                this.setState({
                    showAddCategory: false
                });

                this.reloadCategories();
            }
            else
            {
                this.setState({
                    errMsg: data[0].msg
                });
            }
        })
    }

    showUpdateCategory = (category) => {
        //console.log("Show update category");
        //console.log(category);
        this.setState({
            updateCategory: category,
            updateCategoryVisible: true,
            errMsg: ''
        });
    }

    hideUpdateCategory = () => {
        this.setState({
            updateCategoryVisible: false,
            errMsg: ''
        });
    }

    handleUpdateTitleChange = (e) => {
        let category = this.state.updateCategory;
        category.title = e.target.value;
        this.setState({
            updateCategory: category
        });
    }

    handleUpdateColourChange = (e) => {
        let category = this.state.updateCategory;
        category.colour = e.target.value;
        this.setState({
            updateCategory: category
        });
    }

    handleUpdateBaselineRecordingChange = (e) => {
        let category = this.state.updateCategory;
        category.baselineRecording = e.target.value;

        //console.log("handleUpdateBaselineRecordingChange");
        //console.log(category.baselineRecording);

        this.setState({
            updateCategory: category
        });
    }

    render() {
        const numCategories = this.state.numCategories;
        if (1 === this.props.accessLevel)
        {
            return (<div>

                <Modal
                show={this.state.showAddCategory}
                onHide={this.handleCloseCategory}
                backdrop="static"
                centered
                >
                <Modal.Header closeButton>
                    <Modal.Title><center><h1 class="pageTitle">Add New Category</h1></center></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <CategoryAdd ref={this.frmAddCategory} reloadCategory={this.reloadCategories} />
                </Modal.Body>

                <Modal.Footer>
                    <span className="CategoryAddSpan">{this.state.errMsg}</span>
                    <Button onClick={this.handleSubmitCategory}>Add</Button>
                    <Button onClick={this.handleCloseCategory}>Close</Button>
                </Modal.Footer>
                </Modal>

                <CategoryChange
                updateCategoryVisible={this.state.updateCategoryVisible}
                hideUpdateCategory={this.hideUpdateCategory}
                handleUpdateTitleChange={this.handleUpdateTitleChange}
                handleUpdateColourChange={this.handleUpdateColourChange}
                handleUpdateBaselineRecordingChange={this.handleUpdateBaselineRecordingChange}
                category={this.state.updateCategory}>
                </CategoryChange>

                <Jumbotron className="jumboHeader">
                <center><h1 class="pageTitle">Categories</h1></center>
                <center><h2 class="pageTitle">Category Count: {numCategories}</h2></center>
                </Jumbotron>
                <div class="paginationEvents">{this.renderPagination()}</div>
                <CategoryList showUpdateCategory={this.showUpdateCategory} accessLevel={this.props.accessLevel} reloadCategories={this.reloadCategories} ref="categorylist"/>
                <Button onClick={this.handleAddCategory} className="btnCategory">
                Add Category
                </Button>

                </div>
            )
        }
        else
        {
            return (<div>

                <CategoryChange
                updateCategoryVisible={this.state.updateCategoryVisible}
                hideUpdateCategory={this.hideUpdateCategory}
                handleUpdateTitleChange={this.handleUpdateTitleChange}
                handleUpdateColourChange={this.handleUpdateColourChange}
                handleUpdateBaselineRecordingChange={this.handleUpdateBaselineRecordingChange}
                category={this.state.updateCategory}>
                </CategoryChange>

                <Jumbotron className="jumboHeader">
                <center><h1 class="pageTitle">Categories</h1></center>
                <center><h2 class="pageTitle">Category Count: {numCategories}</h2></center>
                </Jumbotron>
                <div class="paginationEvents">{this.renderPagination()}</div>
                <CategoryList showUpdateCategory={this.showUpdateCategory} accessLevel={this.props.accessLevel} reloadCategories={this.reloadCategories} ref="categorylist"/>
                </div>)
        }
    }
}

export default SettingsPage;
