import React, { Component } from 'react';

import { Table, OverlayTrigger, Pagination, Tooltip } from 'react-bootstrap';

import './HistoryList.css';

class HistoryList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            startIndex: 0,
            pageSize: 10,
            pageId: 1,
            events: []
        };
    }

    componentDidMount() {
        fetch('event_list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "startIndex=" + this.state.startIndex + "&pageSize=" + this.state.pageSize
        })
        .then(res => res.json())
        .then((data) => {
          this.setState({ events: data });
        })
        .catch(console.log)
    }

    handleDelete = id => event => {
        event.preventDefault();

        //const name = ReactDOM.findDOMNode(this._nameInput).value;
        //console.log(event.target.name);
        //console.log(event.target.value);
        fetch('event_delete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "eventid=" + id
        })
        .then(res => res.json())
        .then(data =>
        {
            if ('true' === data[0].delete)
            {
                fetch('event_list')
                .then(res => res.json())
                .then((data) => {
                this.setState({ events: data });
                })
                .catch(console.log);
            }
        })
        .catch(console.log)
    }

    handleDownload = filename => event => {
        event.preventDefault();
    }

    renderListData = () => {
        return this.state.events.map((event) => (
            <tr class="blankBody">
                <td class="blankCell"></td>
                <td class="contentTd">{event.id}</td>
                <td align="left" class="contentTd">
                <OverlayTrigger overlay={<Tooltip id="tooltip" className="infoToolTip" placement="left"><h3>{event.info.substring(0, 4095)}</h3></Tooltip>}>
                <span className="d-inline-block">
                    {event.info.substring(0, 50)}
                </span>
                </OverlayTrigger>

                </td>
                <td class="contentTd">{event.dt}</td>
                <td class="contentTd">{event.username}</td>
                <td class="blankCell"></td>
            </tr>
        ))
        //if (1 === this.props.accessLevel){}
    }

    renderPagination = () => {
        let numPages = 1 + this.props.numEvents / this.state.pageSize;
        let items = [];
        let active = this.state.pageId;

        var iStart = active - 5;
        var iFinish = active + 5;

        if (iStart < 1)
            iStart = 1;

        if (iFinish > numPages)
            iFinish = numPages;

        //for (let number = 1; number <=numPages; number++)
        for (let number = iStart; number <=iFinish; number++)
        {
            items.push(
                <Pagination.Item className="pageEvents" key={number} active={number === active}>
                {number}
                </Pagination.Item>,
            );
        }

        return (
            <Pagination onClick={this.loadPage}>{items}</Pagination>
        )
    }

    loadPage = e => {
        if (typeof e.target.text !== "undefined")
        {
            let newPageId = parseInt(e.target.text);
            let newStartIndex = (newPageId-1) * this.state.pageSize;

            this.setState({
                pageId: newPageId,
                startIndex: newStartIndex
            });

            fetch('event_list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: "startIndex=" + newStartIndex + "&pageSize=" + this.state.pageSize
            })
            .then(res => res.json())
            .then((data) => {
            this.setState({ events: data });
            })
            .catch(console.log)
        }
    }

    render() {
        return (
            <div>
            <div class="paginationEvents">{this.renderPagination()}</div>
            <Table className="historyTable">
            <thead>
                <tr>
                    <th class="absorbingColumn"></th>
                    <th><h3>id</h3></th>
                    <th><h3>event</h3></th>
                    <th><h3>Timestamp</h3></th>
                    <th><h3>User</h3></th>
                    <th class="absorbingColumn"></th>
                </tr>
            </thead>
            {this.renderListData()}
            </Table>
            </div>
        )
    }
}

export default HistoryList;
