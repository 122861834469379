import React, { Component } from 'react';
import { Jumbotron } from 'react-bootstrap';

import HistoryList from './HistoryList.js';

import './HistoryPage.css';

class HistoryPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            numEvents: 0
        };
    }

    componentDidMount() {

        fetch('event_stats')
        .then(res => res.json())
        .then((data) => {

            this.setState({
                numEvents: data[0].numEvents
            });
        })
        .catch(console.log)

    }

    render() {
        const numEvents = this.state.numEvents;
        return (<div>
                <Jumbotron className="jumboHeader">
                <center><h1 class="pageTitle">History</h1></center>
                <center><h2 class="pageTitle">Num Events: {numEvents}</h2></center>
                </Jumbotron>
                <HistoryList accessLevel={this.props.accessLevel} numEvents={this.state.numEvents} />
                </div>)
    }
}

export default HistoryPage;
