import React, { Component } from 'react';
import { Player, BigPlayButton, ControlBar, CurrentTimeDisplay, TimeDivider, PlaybackRateMenuButton, VolumeMenuButton } from 'video-react';

import './Map.css';

import MediaBar from '../Controls/MediaBar.js';

import '../../node_modules/video-react/dist/video-react.css'; // import css

class Map extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isMapLoaded: false,
            mediaBar: null,
            mediaTimeout: null,
            videoHidden: false,

            georeferencedFound: false,
            globalFound: false,
            landmarkreferencedFound: false,
            paintcloudFound: false,

            player1: null,
            player2: null,
            player3: null,
            player4: null,
            player1src: "",
            player2src: "",
            player3src: "",
            player4src: "",
            player1srcExists: false,
            player2srcExists: false,
            player3srcExists: false,
            player4srcExists: false,
            videoActive: false

        };
    }

    //called when object is first mounted
    componentDidMount() {

        const script = document.createElement("script");

        script.src = "createMap.js";
        script.async = true;

        document.body.appendChild(script);

        this.player1.subscribeToStateChange(this.handlePlayer1StateChange.bind(this));
        this.player2.subscribeToStateChange(this.handlePlayer2StateChange.bind(this));
        this.player3.subscribeToStateChange(this.handlePlayer3StateChange.bind(this));
        this.player4.subscribeToStateChange(this.handlePlayer4StateChange.bind(this));
    }

    //is called whenever the dom is rerendered
    componentDidUpdate() {

        if (!this.state.isMapLoaded)
        {
            if (null != this.props.mapFile)
            {
                fetch('recording_info', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    },
                    body: "recording=" + this.props.mapFile
                })
                .then(res => res.json())
                .then((data) => {

                    this.setState({
                        georeferencedFound: data[0].georeferencedConverted,
                        globalFound: data[0].globalConverted,
                        landmarkreferencedFound: data[0].landmarkreferencedConverted,
                        paintcloudFound: data[0].paintcloudConverted,
                    });


                    const script = document.createElement("script");

                    script.src = "loadMap.js";
                    script.async = true;
                    script.setAttribute("georeferencedFound", data[0].georeferencedConverted);
                    script.setAttribute("globalFound", data[0].globalConverted);
                    script.setAttribute("landmarkreferencedFound", data[0].landmarkreferencedConverted);
                    script.setAttribute("paintcloudFound", data[0].paintcloudConverted);
                    script.setAttribute("mapFile", this.props.mapFile);

                    document.body.appendChild(script);

                    this.setState({
                        isMapLoaded: true
                    });

                    //console.log("loading players");

                    var p1src = "maps/" + this.props.mapFile + "/camera0.mp4";
                    var p2src = "maps/" + this.props.mapFile + "/camera1.mp4";
                    var p3src = "maps/" + this.props.mapFile + "/camera2.mp4";
                    var p4src = "maps/" + this.props.mapFile + "/camera3.mp4";
                    var p1srcExists = data[0].camera0;
                    var p2srcExists = data[0].camera1;
                    var p3srcExists = data[0].camera2;
                    var p4srcExists = data[0].camera3;
                    var videoActive = p1srcExists || p2srcExists || p3srcExists || p4srcExists;

                    this.setState({
                        player1src: p1src,
                        player2src: p2src,
                        player3src: p3src,
                        player4src: p4src,
                        player1srcExists: p1srcExists,
                        player2srcExists: p2srcExists,
                        player3srcExists: p3srcExists,
                        player4srcExists: p4srcExists,
                        videoActive: videoActive
                    });

                    /*
                    console.log("Does file exist");
                    console.log(p1srcExists);
                    console.log(p2srcExists);
                    console.log(p3srcExists);
                    console.log(p4srcExists);
                    */

                    if (p1srcExists)
                        this.player1.load();

                    if (p2srcExists)
                        this.player2.load();

                    if (p3srcExists)
                        this.player3.load();

                    if (p4srcExists)
                        this.player4.load();
                })
                .catch(console.log)
            }
        }
    }

    doesFileExist = (urlToFile) => {
        var xhr = new XMLHttpRequest();
        xhr.open('HEAD', urlToFile, false);
        xhr.send();

        return xhr.status !== 404;
    }

    resetMap = () => {

        this.setState({
            isMapLoaded: false
        });
    }

    handlePlayer1StateChange(state) {
    // copy player state to this component's state

        if (null != this.state.player1)
        {
            if (!this.state.player1.hasStarted && state.hasStarted) //The video was just started
            {
                //console.log("video has just started!");
                this.player2.play();
                this.player3.play();
                this.player4.play();
                this.mediaBar.setPlaying();
            }

            if (this.state.player1.hasStarted && state.hasStarted) //is already started and then paused
            {
                if (!this.state.player1.paused && state.paused) //playback paused
                {
                    //console.log("video hgas just stopped!");
                    this.player2.pause();
                    this.player3.pause();
                    this.player4.pause();

                    if (null != this.state.mediaTimeout)
                    {
                        clearTimeout(this.state.mediaTimeout);
                        this.setState({
                            mediaTimeout: null
                        });
                    }

                    this.mediaBar.setPaused();

                }

                if (this.state.player1.paused && !state.paused) //playback resumed
                {
                    //console.log("video hgas just resumed!");
                    this.player2.play();
                    this.player3.play();
                    this.player4.play();
                    this.mediaBar.setPlaying();
                }
            }

            if (this.state.player1.playbackRate !== state.playbackRate)
            {
                //console.log("playback Rate changed!");
                this.player2.playbackRate = state.playbackRate;
                this.player3.playbackRate = state.playbackRate;
                this.player4.playbackRate = state.playbackRate;
            }

            if (!this.state.player1.seeking && state.seeking)
            {
                //console.log("Seeking " + state.currentTime + "!");
                this.player2.seek(state.currentTime);
                this.player3.seek(state.currentTime);
                this.player4.seek(state.currentTime);

                //console.log(this.player1.duration);
                this.mediaBar.setValue(100.0 * state.currentTime / this.state.player1.duration);
            }
        }

    this.setState({
        player1: state
    });
    //console.log("player 1 state change");
    //console.log(state);
    }

    handlePlayer2StateChange(state) {

        if (null != this.state.player2)
        {
            if (!this.state.player2.hasStarted && state.hasStarted) //The video was just started
            {
                //console.log("video has just started!");
                this.player1.play();
                this.player3.play();
                this.player4.play();
                this.mediaBar.setPlaying();
            }

            if (this.state.player2.hasStarted && state.hasStarted) //is already started and then paused
            {
                if (!this.state.player2.paused && state.paused) //playback paused
                {
                    //console.log("video hgas just stopped!");
                    this.player1.pause();
                    this.player3.pause();
                    this.player4.pause();

                    if (null != this.state.mediaTimeout)
                    {
                        clearTimeout(this.state.mediaTimeout);
                        this.setState({
                            mediaTimeout: null
                        });
                    }
                    this.mediaBar.setPaused();
                }

                if (this.state.player2.paused && !state.paused) //playback resumed
                {
                    //console.log("video hgas just resumed!");
                    this.player1.play();
                    this.player3.play();
                    this.player4.play();
                    this.mediaBar.setPlaying();
                }
            }

            if (this.state.player2.playbackRate !== state.playbackRate)
            {
                //console.log("playback Rate changed!");
                this.player1.playbackRate = state.playbackRate;
                this.player3.playbackRate = state.playbackRate;
                this.player4.playbackRate = state.playbackRate;
            }

            if (!this.state.player2.seeking && state.seeking)
            {
                //console.log("Seeking " + state.currentTime + "!");
                this.player1.seek(state.currentTime);
                this.player3.seek(state.currentTime);
                this.player4.seek(state.currentTime);
                this.mediaBar.setValue(100.0 * state.currentTime / this.state.player2.duration);
            }
        }

    this.setState({
        player2: state
    });
    }

    handlePlayer3StateChange(state) {
        if (null != this.state.player3)
        {
            if (!this.state.player3.hasStarted && state.hasStarted) //The video was just started
            {
                //console.log("video has just started!");
                this.player1.play();
                this.player2.play();
                this.player4.play();
                this.mediaBar.setPlaying();
            }

            if (this.state.player3.hasStarted && state.hasStarted) //is already started and then paused
            {
                if (!this.state.player3.paused && state.paused) //playback paused
                {
                    //console.log("video hgas just stopped!");
                    this.player1.pause();
                    this.player2.pause();
                    this.player4.pause();

                    if (null != this.state.mediaTimeout)
                    {
                        clearTimeout(this.state.mediaTimeout);
                        this.setState({
                            mediaTimeout: null
                        });
                    }
                    this.mediaBar.setPaused();
                }

                if (this.state.player3.paused && !state.paused) //playback resumed
                {
                    //console.log("video hgas just resumed!");
                    this.player1.play();
                    this.player2.play();
                    this.player4.play();
                    this.mediaBar.setPlaying();
                }
            }

            if (this.state.player3.playbackRate !== state.playbackRate)
            {
                //console.log("playback Rate changed!");
                this.player1.playbackRate = state.playbackRate;
                this.player2.playbackRate = state.playbackRate;
                this.player4.playbackRate = state.playbackRate;
            }

            if (!this.state.player3.seeking && state.seeking)
            {
                //console.log("Seeking " + state.currentTime + "!");
                this.player1.seek(state.currentTime);
                this.player2.seek(state.currentTime);
                this.player4.seek(state.currentTime);
                this.mediaBar.setValue(100.0 * state.currentTime / this.state.player3.duration);
            }
        }

    this.setState({
        player3: state
    });
    }

    handlePlayer4StateChange(state)
    {
        if (null != this.state.player4)
        {
            if (!this.state.player4.hasStarted && state.hasStarted) //The video was just started
            {
                //console.log("video has just started!");
                this.player1.play();
                this.player2.play();
                this.player3.play();
                this.mediaBar.setPlaying();
            }

            if (this.state.player4.hasStarted && state.hasStarted) //is already started and then paused
            {
                if (!this.state.player4.paused && state.paused) //playback paused
                {
                    //console.log("video hgas just stopped!");
                    this.player1.pause();
                    this.player2.pause();
                    this.player3.pause();

                    if (null != this.state.mediaTimeout)
                    {
                        clearTimeout(this.state.mediaTimeout);
                        this.setState({
                            mediaTimeout: null
                        });
                    }
                    this.mediaBar.setPaused();
                }

                if (this.state.player4.paused && !state.paused) //playback resumed
                {
                    //console.log("video hgas just resumed!");
                    this.player1.play();
                    this.player2.play();
                    this.player3.play();
                    this.mediaBar.setPlaying();
                }
            }

            if (this.state.player4.playbackRate !== state.playbackRate)
            {
                //console.log("playback Rate changed!");
                this.player1.playbackRate = state.playbackRate;
                this.player2.playbackRate = state.playbackRate;
                this.player3.playbackRate = state.playbackRate;
            }

            if (!this.state.player4.seeking && state.seeking)
            {
                //console.log("Seeking " + state.currentTime + "!");
                this.player1.seek(state.currentTime);
                this.player2.seek(state.currentTime);
                this.player3.seek(state.currentTime);
                this.mediaBar.setValue(100.0 * state.currentTime / this.state.player4.duration);
            }
        }

        this.setState({
            player4: state
        });
    }

    mediaBarChangeHandler(value)
    {
        const seekTime = 0.01 * value * this.state.player1.duration;
        //console.log("media change handler activated!" + value + " is seek time " + seekTime);
        this.player1.seek(seekTime);
        this.player2.seek(seekTime);
        this.player3.seek(seekTime);
        this.player4.seek(seekTime);
        this.player1.play();
        this.player2.play();
        this.player3.play();
        this.player4.play();

        if (null == this.state.mediaTimeout)
        {
            const timeout = setInterval( () => {this.updateMediaBar()}, 1000);
            this.setState({
                mediaTimeout: timeout
            });
        }
    }

    updateMediaBar()
    {
        //console.log("Update media bar");
        this.mediaBar.setValue(100.0 * this.state.player1.currentTime / this.state.player1.duration, this.state.player1.currentTime, this.state.player1.duration);
    }

    mediaBarPauseHandler()
    {
        //console.log("Pause handler");
        clearTimeout(this.state.mediaTimeout);
        this.setState({
            mediaTimeout: null
        });

        this.player1.pause();
        this.player2.pause();
        this.player3.pause();
        this.player4.pause();
    }

    hideMediaBar()
    {
        console.log("hideMediaBar");

        this.setState({
            videoHidden: true
        });
    }

    showMediaBar()
    {
        console.log("showMediaBar");

        this.setState({
            videoHidden: false
        });
    }

    render() {
        return (
            <div className={this.props.isHidden ? 'potree_container_hidden' : 'potree_container'}>
                <div id="potree_render_area">
                    <div id="potree_toolbar" class="potree_toolbar"></div>
                </div>
                <div id="potree_sidebar_container"> </div>
                <div class={this.state.videoActive ? (this.state.videoHidden ? "MediaBarHidden" : "MediaBar") : "MediaBarDisabled"}><MediaBar ref={mediaBar => { this.mediaBar = mediaBar; }} onChange = {(value) => {this.mediaBarChangeHandler(value)}} Pause = {() => {this.mediaBarPauseHandler()}} hideBar = {() => {this.hideMediaBar()}} showBar = {() => {this.showMediaBar()}}/></div>
                <div className={this.state.videoActive ? (this.state.videoHidden ? "video_bar_hidden" : "video_bar") : "video_bar_hidden" }>

                    <Player className={this.state.player1srcExists ? "video1Enabled" : "video1Disabled"} ref={player => { this.player1 = player; }} src={this.state.player1src}>

                        <BigPlayButton position="center" />

                        <ControlBar>
                            <CurrentTimeDisplay order={4.1} />
                            <TimeDivider order={4.2} />
                            <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                            <VolumeMenuButton disabled />
                        </ControlBar>
                    </Player>

                    <Player className={this.state.player2srcExists ? "video2Enabled" : "video2Disabled"} ref={player => { this.player2 = player; }} src={this.state.player2src}>

                        <BigPlayButton position="center" />

                        <ControlBar>
                            <CurrentTimeDisplay order={4.1} />
                            <TimeDivider order={4.2} />
                            <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                            <VolumeMenuButton disabled />
                        </ControlBar>
                    </Player>

                    <Player className={this.state.player3srcExists ? "video3Enabled" : "video3Disabled"} ref={player => { this.player3 = player; }} src={this.state.player3src}>

                        <BigPlayButton position="center" />

                        <ControlBar>
                            <CurrentTimeDisplay order={4.1} />
                            <TimeDivider order={4.2} />
                            <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                            <VolumeMenuButton disabled />
                        </ControlBar>
                    </Player>

                    <Player className={this.state.player4srcExists ? "video4Enabled" : "video4Disabled"} ref={player => { this.player4 = player; }} src={this.state.player4src}>

                        <BigPlayButton position="center" />

                        <ControlBar>
                            <CurrentTimeDisplay order={4.1} />
                            <TimeDivider order={4.2} />
                            <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                            <VolumeMenuButton disabled />
                        </ControlBar>
                    </Player>
                </div>
            </div>)
    }
}

export default Map;
