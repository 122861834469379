import React, { Component } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';

import './WorkflowChange.css';

class WorkflowChange extends Component {

    constructor(props) {
        super(props);

        this.state = {
            categoryRecordings: [],
            errMsg: ''
        };
    }

    onLoad = () => {
        //console.log("Modal is loaded");
    }

    render() {
        return (

            <Modal
              show={this.props.showUpdateWorkflow}
              onHide={this.props.hideUpdateWorkflow}
              onEntered={this.onLoad}
              backdrop="static"
              centered
            >
            <Modal.Header closeButton>
                <Modal.Title><center><h1 class="pageTitle">Update Workflow</h1></center></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div>
                <Form className="ProcessChangeForm" onSubmit={this.handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="frmName">
                            <Form.Label class="WorkflowLabel">Name</Form.Label>
                            <Form.Control className="ProcessUpdate" type="text" placeholder="Name" value={this.props.workflow.Name} onChange={this.props.handleUpdateName}/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="frmDescription">
                            <Form.Label class="WorkflowLabel">Description</Form.Label>
                            <Form.Control className="ProcessUpdate" type="text" placeholder="Description" value={this.props.workflow.Description} onChange={this.props.handleUpdateDescription}/>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Label class="WorkflowLabel">Sequence</Form.Label>
                        <Form.Control className="ProcessUpdate" type="text" placeholder="Description" value={this.props.workflow.Sequence} onChange={this.props.handleUpdateSequence}/>
                    </Form.Row>

                </Form>
            </div>
            </Modal.Body>

            <Modal.Footer>
                <span className="ProcessAddErr">{this.state.errMsg}</span>
                <Button onClick={this.props.handleUpdateWorkflow}>Update</Button>
                <Button onClick={this.props.hideUpdateWorkflow}>Close</Button>
            </Modal.Footer>
            </Modal>
        )
    }
}

export default WorkflowChange;
