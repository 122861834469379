import React, { Component } from 'react';
import { Modal, Table, Button } from 'react-bootstrap';

import './ChangeMapSelector.css';

import SystemUtils from '../SystemUtils';

class ChangeMapSelector extends Component {

    constructor(props) {
        super(props);

        this.state = {
            atlasRecording: '',
            category: "",
            recordings: [],
            showPopup: false,
            ChangemapMsg: 'Choose a basemap to create a difference map with',
            errMsg: ''
        };
    }

    show = (recording, category) => {

        console.log("show cloud list for atlas " + recording);

        this.setState({
            atlasRecording: recording
        });

        fetch('cloud_list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "category=" + category + "&recording=" + recording
        })
        .then(res => res.json())
        .then((data) => {
            this.setState({
                showPopup: true,
                recordings: data
            });
        })
        .catch(console.log)
    }

    handleClose = () => {
        this.setState({ showPopup: false });
        this.props.reloadCloudList();
    }

    handleMapChangemapEvent = (e) => {
        var objChangeMap = JSON.parse(e.data);

        //console.log(objChangeMap);

        this.setState({
            ChangemapMsg:  objChangeMap.msg
        });

        if (2 === objChangeMap.conversionState)
        {
            this.eventSource.close();

            this.handleClose();


            /*
            fetch('cloud_list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: "startIndex=" + this.state.startIndex + "&pageSize=" + this.state.pageSize
            })
            .then(res => res.json())
            .then((data) => {
            this.setState({ files: data });
            })
            .catch(console.log)
            */
        }
        else if (-1 === objChangeMap.conversionState)
        {
            this.eventSource.close();

            this.handleClose();

            /*
            fetch('cloud_list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: "startIndex=" + this.state.startIndex + "&pageSize=" + this.state.pageSize
            })
            .then(res => res.json())
            .then((data) => {
            this.setState({ files: data });
            })
            .catch(console.log)
            */
        }
    }

    handleCreateChangemap = (filename) => event => {
        event.preventDefault();

        console.log('create Changemap ' + filename);

        this.eventSource = new EventSource('map_create_changemap?atlas=' + this.state.atlasRecording + "&recording=" + filename);
        this.eventSource.onmessage = e => this.handleMapChangemapEvent(e);
        /*
        fetch('map_create_changemap', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: "atlas=" + this.state.atlasRecording + "&recording=" + filename
            })
            .then(res => res.json())
            .then((data) => {
                this.handleClose();
            })
            .catch(console.log)
        */

    }

    renderRecordings = () => {

        return this.state.recordings.map((recordings) => (
            <tbody>
                <tr>
                    <td>
                        {recordings.filename}
                    </td>
                    <td>
                        {recordings.category}
                    </td>
                    <td>
                        {recordings.uploadDate}
                    </td>
                    <td>
                        {SystemUtils.GetSizeString(recordings.size)}
                    </td>
                    <td>
                        <form class="form-inline" name="frmChange" onSubmit={this.handleCreateChangemap(recordings.filename)}>
                        <Button variant="danger" size="lg" type="submit">Create</Button>
                        </form>
                    </td>
                </tr>
            </tbody>
        ))
    }

    render() {
        return (
            <div>

            <Modal
              dialogClassName="changeMapInfoModal"
              show={this.state.showPopup}
              onHide={this.handleClose}
              backdrop="static"
              centered
            >
            <Modal.Header closeButton>
                <Modal.Title><center><h1 class="pageTitle">Create Difference Map</h1></center></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Table className="recordingTable">
                <thead>
                    <tr>
                        <th class="changemapFile"><h3>File</h3></th>
                        <th class="changemapFileSize"><h3>File Size</h3></th>
                        <th class="changemapLastUpdated"><h3>Last Updated</h3></th>
                        <th class="changemapError"><h3>Size</h3></th>
                        <th class="changemapFileAbsorbingColumn"></th>
                    </tr>
                </thead>
                {this.renderRecordings()}
                </Table>
            </Modal.Body>

            <Modal.Footer>
            <center><h3 class="changeMsg">{this.state.ChangemapMsg}</h3></center>
            </Modal.Footer>
            </Modal>

            </div>
        )
    }
}

export default ChangeMapSelector;
