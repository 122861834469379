import React, { Component } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';

import './ProcessAdd.css';

class ProcessAdd extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            command: '',
            description: '',
            errMsg: ''
        };
    }

    GetName = () => {
        return this.state.name
    }

    GetCommand = () => {
        return this.state.command
    }

    GetDescription = () => {
        return this.state.description
    }

    GetError = () => {
        return this.state.errMsg
    }

    handleNameChange = (e) => {
        this.setState({
            name: e.target.value,
            errMsg: ''
        });
    }

    handleCommandChange = (e) => {
        this.setState({
            command: e.target.value,
            errMsg: ''
        });
    }

    handleDescriptionChange = (e) => {
        this.setState({
            description: e.target.value,
            errMsg: ''
        });
    }

    handleSubmitAddProcess = (e) => {
        console.log("handleSubmitAddProcess");

        e.preventDefault();
        e.stopPropagation();

        fetch('process_add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "name=" + this.state.name + "&command=" + this.state.command + "&description=" + this.state.description
        })
        .then(res => res.json())
        .then(data =>
        {
            console.log(data);
            if ('true' === data[0].Success)
            {
                this.setState({
                    errMsg: data[0].Msg
                });

                this.props.reloadProcesses();
                this.props.hideAddProcess();
            }
            else
            {
                this.setState({
                    errMsg: data[0].Msg
                });
            }
        })
    }

    render() {
        return (

            <Modal
              show={this.props.showAddProcess}
              onHide={this.props.hideAddProcess}
              backdrop="static"
              centered
            >
            <Modal.Header closeButton>
                <Modal.Title><center><h1 class="pageTitle">Add Process</h1></center></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div>
                <Form className="ProcessAddForm" onSubmit={this.handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="frmName">
                            <Form.Label class="ProcessAddLabel">Name</Form.Label>
                            <Form.Control className="ProcessAddControl" type="text" placeholder="name" value={this.state.name} onChange={this.handleNameChange}/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="frmCommand">
                            <Form.Label class="ProcessAddLabel">Command</Form.Label>
                            <Form.Control className="ProcessAddControl" type="text" placeholder="command" value={this.state.command} onChange={this.handleCommandChange}/>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="frmDescription">
                            <Form.Label class="ProcessAddLabel">Description</Form.Label>
                            <Form.Control className="ProcessAddControl" type="text" placeholder="Description" value={this.state.description} onChange={this.handleDescriptionChange} />
                        </Form.Group>
                    </Form.Row>
                </Form>
            </div>
            </Modal.Body>

            <Modal.Footer>
                <span className="userAddErr">{this.state.errMsg}</span>
                <Button onClick={this.handleSubmitAddProcess}>Add</Button>
                <Button onClick={this.props.hideAddProcess}>Close</Button>
            </Modal.Footer>
            </Modal>
        )
    }
}

export default ProcessAdd;
