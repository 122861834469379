import React, { Component } from 'react';

import { Alert, Button, Table, Jumbotron, Pagination } from 'react-bootstrap';

import WorkflowAdd from './WorkflowAdd.js';
import WorkflowChange from './WorkflowChange.js';

import './Workflows.css';

class Workflows extends Component {

    constructor(props) {
        super(props);

        let workflow = {
            WorkflowId: -1,
            Name: "",
            Description: "",
            Sequence: ""
        }

        this.state = {
            list: [],
            errMsg: '',
            showAddWorkflow: false,
            addProcessErrMsg: '',
            renderAlert: false,
            alertMsg: '',
            alertSuccess: false,
            showUpdateWorkflow: false,
            workflowToUpdate: workflow
        };
    }

    componentDidMount() {
        fetch('workflow_list')
        .then(res => res.json())
        .then((data) => {
            this.setState({
                list: data
            });
        })
        .catch(console.log)
    }

    reloadPage = () => {
        this.refs.mapList.reloadPage();
    }

    renderPagination = () => {
        let numPages = Math.ceil((this.props.numClouds) / this.state.pageSize);
        let items = [];
        let active = this.state.pageId;

        var iStart = active - 10;
        var iFinish = active + 10;

        if (iStart < 1)
            iStart = 1;

        if (iFinish > numPages)
            iFinish = numPages;

        //for (let number = 1; number <=numPages; number++)
        for (let number = iStart; number <=iFinish; number++)
        {
            items.push(
                <Pagination.Item className="pageClouds" key={number} active={number === active}>
                {number}
                </Pagination.Item>,
            );
        }

        return (
            <Pagination onClick={this.loadPage}>{items}</Pagination>
        )
    }

    handleDelete = (workflowId) => event => {
        event.preventDefault();

        fetch('workflow_delete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "workflowId=" + workflowId
        })
        .then(res => res.json())
        .then(data =>
        {
            if ('true' === data[0].delete)
            {
                fetch('workflow_list')
                .then(res => res.json())
                .then((data) => {
                    this.setState({
                        list: data
                    });
                })
                .catch(console.log);
            }
            else
            {
                this.setState({
                    renderAlert: true,
                    alertMsg: data[0].msg,
                    alertSuccess: false
                });

                setTimeout(this.hideAlert, 3000)
            }
        })
        .catch(console.log)

    }

    renderHeader = () => {
        return (
            <thead>
                <tr>
                    <th><h3>Workflow Id</h3></th>
                    <th><h3>Name</h3></th>
                    <th><h3>Description</h3></th>
                    <th><h3>Sequence</h3></th>
                    <th></th>
                </tr>
            </thead>
        )
    }

    renderListData = () => {
        return this.state.list.map((workflow) => (
            <tbody>
                <tr>
                    <td className="workflowList" onClick={() => this.showUpdateWorkflow(workflow)}>{workflow.WorkflowId}</td>
                    <td className="workflowList" onClick={() => this.showUpdateWorkflow(workflow)}>{workflow.Name}</td>
                    <td className="workflowList" onClick={() => this.showUpdateWorkflow(workflow)}>{workflow.Description}</td>
                    <td className="workflowList" onClick={() => this.showUpdateWorkflow(workflow)}>{workflow.Sequence}</td>
                    <td className="workflowList">
                        <form name="frmDelete" onSubmit={this.handleDelete(workflow.WorkflowId)}>
                        <Button variant="danger" size="lg" type="submit">Delete</Button>
                        </form>
                    </td>
                </tr>
            </tbody>
        ))
    }

    reloadWorkflows = () => {
        console.log("reloadWorkflows");
        fetch('workflow_list')
        .then(res => res.json())
        .then((data) => {
            this.setState({
                list: data
            });
        })
        .catch(console.log)
    }

    showAddWorkflow = () => {
        console.log("Show Add workflow");
        this.setState({
            showAddWorkflow: true
        });
    }

    hideAddWorkflow = () => {
        this.setState({
            showAddWorkflow: false
        });
    }

    hideAlert = () =>
    {
        this.setState({
            renderAlert: false
        });
    }

    renderAlert = () =>
    {
        if (this.state.renderAlert)
        {
            if (this.state.alertSuccess)
            {
                return (<Alert key="success" variant="success">
                            {this.state.alertMsg}
                        </Alert>);
            }
            else
            {
                return (<Alert key="danger" variant="danger">
                            {this.state.alertMsg}
                        </Alert>);
            }
        }

    }

    reloadSettings = () => {
        this.refs.categorylist.reloadList(this.state.pageId, this.state.pageSize);
    }

    hideUpdateWorkflow = () => {
        this.setState({
            showUpdateWorkflow: false,
            errMsg: ''
        });
    }

    showUpdateWorkflow = (workflow) => {
        console.log("Show update workflow");
        console.log(workflow);
        this.setState({
            workflowToUpdate: workflow,
            showUpdateWorkflow: true,
            errMsg: ''
        });
    }

    handleUpdateName = (e) => {
        let workflow = this.state.workflowToUpdate;
        workflow.Name = e.target.value;
        this.setState({
            workflowToUpdate: workflow
        });
    }

    handleUpdateDescription = (e) => {
        let workflow = this.state.workflowToUpdate;
        workflow.Description = e.target.value;
        this.setState({
            workflowToUpdate: workflow
        });
    }

    handleUpdateSequence = (e) => {
        let workflow = this.state.workflowToUpdate;
        workflow.Sequence = e.target.value;
        this.setState({
            workflowToUpdate: workflow
        });
    }

    handleUpdateWorkflow = () => {
        let workflow = this.state.workflowToUpdate;

        console.log("updateProcess");
        console.log(workflow);

        this.setState({
            workflowToUpdate: workflow
        });

        fetch('workflow_update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "WorkflowId=" + workflow.WorkflowId + "&Name=" + workflow.Name + "&Description=" + workflow.Description + "&Sequence=" + workflow.Sequence
        })
        .then(res => res.json())
        .then(data =>
        {
            if ('true' === data[0].update)
            {
                this.setState({
                    errMsg: data[0].msg,
                    showUpdateWorkflow: false
                });
            }
            else
            {
                this.setState({
                    errMsg: data[0].msg,
                    showUpdateWorkflow: false
                });
            }

            this.reloadWorkflows();
        })
    }

    render() {
        return (<div>

                <WorkflowChange
                showUpdateWorkflow={this.state.showUpdateWorkflow}
                hideUpdateWorkflow={this.hideUpdateWorkflow}
                handleUpdateWorkflow={this.handleUpdateWorkflow}
                handleUpdateName={this.handleUpdateName}
                handleUpdateDescription={this.handleUpdateDescription}
                handleUpdateSequence={this.handleUpdateSequence}
                workflow={this.state.workflowToUpdate}>
                </WorkflowChange>

                <WorkflowAdd hideAddWorkflow={this.hideAddWorkflow} showAddWorkflow={this.state.showAddWorkflow} reloadWorkflows={this.reloadWorkflows}></WorkflowAdd>

                <Jumbotron className="jumboHeader">
                <center><h1 class="workflow-title">Workflows</h1></center>
                <Button className="btnAddWorkflow" size="lg" onClick={this.showAddWorkflow}>Add Workflow</Button>
                </Jumbotron>
                <div class="paginationClouds">{this.renderPagination()}</div>
                <Table className="pipelineList">
                {this.renderHeader()}
                {this.renderListData()}
                </Table>
                {this.renderAlert()}

                </div>)
    }
}

export default Workflows;
