import React, { Component } from 'react';
import { ProgressBar } from 'react-bootstrap';

import './ModalConversion.css';

class ModalConversion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            conversionPercentage: 100
        };
    }

    render() {
        return (
            <div>
            <ProgressBar className="prgConvertMap" animated now={this.state.conversionPercentage} label={this.props.conversionMsg}/>
            </div>
        )
    }
}

export default ModalConversion;
