import React, { Component } from 'react';
import { Jumbotron, Form, Button, Col } from 'react-bootstrap';

import './ProfileEdit.css';

class ProfileEdit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            lastLogin: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            password: '',
            newPassword: '',
            errMsg: ''
        };
    }

    componentDidMount() {
        fetch('user_info')
        .then(res => res.json())
        .then((data) => {

            this.setState({
                username: data[0].username,
                lastLogin: data[0].lastLogin,
                firstName: data[0].firstName,
                lastName: data[0].lastName,
                email: data[0].email,
                phone: data[0].phone,
                password: '',
                newPassword: '',
                errMsg: ''
            });
        })
    }

    handleSubmit = (event) => {
        //const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        fetch('user_update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "username=" + this.state.username + "&firstName=" + this.state.firstName + "&lastName=" + this.state.lastName + "&email=" + this.state.email + "&phone=" + this.state.phone + "&password=" + this.state.password + "&newPassword=" + this.state.newPassword
        })
        .then(res => res.json())
        .then(data =>
        {
            fetch('event_add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: "info=Update profile"
            })

            if ('true' === data[0].update)
            {
                this.setState({
                    password: '',
                    newPassword: '',
                    errMsg: data[0].msg
                });
            }
            else
            {
                this.setState({
                    errMsg: data[0].msg
                });
            }
        })
    }

    handleFirstNameChange = (e) => {
        this.setState({
            firstName: e.target.value,
            errMsg: ''
        });
    }

    handleLastNameChange = (e) => {
        this.setState({
            lastName: e.target.value,
            errMsg: ''
        });
    }

    handleEmailChange = (e) => {
        this.setState({
            email: e.target.value,
            errMsg: ''
        });
    }

    handlePhoneChange = (e) => {
        this.setState({
            phone: e.target.value,
            errMsg: ''
        });
    }

    handlePasswordChange = (e) => {
        this.setState({
            password: e.target.value,
            errMsg: ''
        });
    }

    handleNewPasswordChange = (e) => {
        this.setState({
            newPassword: e.target.value,
            errMsg: ''
        });
    }

    render() {
        return (<div>
                    <Jumbotron className="jumboHeader">
                    <center><h1 class="pageTitle">User Profile</h1></center>
                    </Jumbotron>
                    <Form className="profileEditForm" onSubmit={this.handleSubmit}>
                        <Form.Row>
                            <Form.Group as={Col} controlId="frmUsername">
                                <Form.Label class="profileEditLabel">Username</Form.Label>
                                <Form.Control disabled className="profileEditControl" type="text" placeholder={this.state.username} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="frmLastLogin">
                                <Form.Label class="profileEditLabel">Last Login</Form.Label>
                                <Form.Control disabled className="profileEditControl" type="text" placeholder={this.state.lastLogin} />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} controlId="frmFirstName">
                                <Form.Label class="profileEditLabel">First Name</Form.Label>
                                <Form.Control className="profileEditControl" type="text" placeholder="First Name" value={this.state.firstName} onChange={this.handleFirstNameChange} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="frmLastName">
                                <Form.Label class="profileEditLabel">Last Name</Form.Label>
                                <Form.Control className="profileEditControl" type="text" placeholder="Last Name" value={this.state.lastName} onChange={this.handleLastNameChange} />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} controlId="frmEmail">
                                <Form.Label class="profileEditLabel">Email Address</Form.Label>
                                <Form.Control className="profileEditControl" type="email" placeholder="Email Address" value={this.state.email} onChange={this.handleEmailChange}/>
                            </Form.Group>

                            <Form.Group as={Col} controlId="frmPhone">
                                <Form.Label class="profileEditLabel">Phone</Form.Label>
                                <Form.Control className="profileEditControl" type="text" placeholder="Phone Number" value={this.state.phone} onChange={this.handlePhoneChange}/>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} controlId="frmPassword">
                                <Form.Label class="profileEditLabel">Password</Form.Label>
                                <Form.Control className="profileEditControl" type="password" placeholder="Password" value={this.state.password} onChange={this.handlePasswordChange}/>
                            </Form.Group>

                            <Form.Group as={Col} controlId="frmNewPassword">
                                <Form.Label class="profileEditLabel">New Password</Form.Label>
                                <Form.Control className="profileEditControl" type="password" placeholder="New Password" value={this.state.newPassword} onChange={this.handleNewPasswordChange}/>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} controlId="frmSubmit" className="profileEditSubmit">
                                <Button variant="danger" size="lg" type="submit">Update</Button>
                                <Form.Label class="profileEditSubmitLabel">{this.state.errMsg}</Form.Label>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                </div>)
    }
}

export default ProfileEdit;
