import React, { Component } from 'react';

import { Button, Col, Container, Jumbotron, Pagination, ProgressBar, Row, Table } from 'react-bootstrap';

import './Pipeline.css';

class Pipeline extends Component {

    constructor(props) {
        super(props);

        this.state = {
            list: [],
            errMsg: '',
            statusUpdateReady: true,
            clock: null,
            activeProcessPercentage: 0,
            activeProcessMsg: "",
            activeProcessRecording: "",
            activeProcessName: "",
            updateListRequired: false
        };

        this.updateActiveProcess = this.updateActiveProcess.bind(this);
    }

    componentDidMount() {
        fetch('pipeline_list')
        .then(res => res.json())
        .then((data) => {
            this.setState({
                list: data
            });
        })
        .catch(console.log)

        var clk = setInterval(this.updateActiveProcess, 1000);
        this.setState({
            clock: clk,
            updateListRequired: true
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.clock);
    }

    updateActiveProcess() {

        if (this.state.statusUpdateReady)
        {
            this.setState({ statusUpdateReady: false });

            fetch('pipeline_active', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(res => res.json())
            .then(data =>
            {
                //console.log("pipeline_active");
                //console.log(data[0].Recording);
                //console.log(this.state.activeProcessRecording);
                //console.log(data[0].ProcessName);
                //console.log(this.state.activeProcessName);

                //when a process finishes executing and another scan is queued, sometimes the database can be update with the next process before the ui gets a chance to update
                if ((data[0].Recording !== this.state.activeProcessRecording) || (data[0].ProcessName !== this.state.activeProcessName))
                {
                    fetch('pipeline_list')
                    .then(res => res.json())
                    .then((data) => {
                        this.setState({
                            list: data
                        });
                    })
                    .catch(console.log)
                }

                this.setState({
                    statusUpdateReady: true,
                    activeProcessPercentage: data[0].Completion,
                    activeProcessMsg: "Recording " + data[0].Recording + ", " + data[0].Msg,
                    activeProcessRecording: data[0].Recording,
                    activeProcessName: data[0].ProcessName
                });

                //console.log(data[0]);
                //console.log(data[0].ProcessState);
                //console.log(this.state.updateListRequired);

                if ((2 === parseInt(data[0].ProcessState)) && (this.state.updateListRequired))
                {
                    //console.log('process state complete');

                    fetch('pipeline_list')
                    .then(res => res.json())
                    .then((data) => {
                        this.setState({
                            list: data,
                            updateListRequired: false
                        });
                    })
                    .catch(console.log)
                }
            })
            .catch(console.log)
        }
    }

    reloadPage = () => {
        this.refs.mapList.reloadPage();
    }

    renderPagination = () => {
        let numPages = Math.ceil((this.props.numClouds) / this.state.pageSize);
        let items = [];
        let active = this.state.pageId;

        var iStart = active - 5;
        var iFinish = active + 5;

        if (iStart < 1)
            iStart = 1;

        if (iFinish > numPages)
            iFinish = numPages;

        //for (let number = 1; number <=numPages; number++)
        for (let number = iStart; number <=iFinish; number++)
        {
            items.push(
                <Pagination.Item className="pageClouds" key={number} active={number === active}>
                {number}
                </Pagination.Item>,
            );
        }

        return (
            <Pagination onClick={this.loadPage}>{items}</Pagination>
        )
    }

    renderHeader = () => {
        return (
            <thead>
                <tr>
                    <th><h3>Process Name</h3></th>
                    <th><h3>Start Time</h3></th>
                    <th><h3>Command</h3></th>
                    <th><h3>Recording</h3></th>
                    <th></th>
                </tr>
            </thead>
        )
    }

    handleDelete = (processId, recording) => event => {
        event.preventDefault();

        fetch('pipeline_delete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "processId=" + processId + "&recording=" + recording
        })
        .then(res => res.json())
        .then(data =>
        {
            if ('true' === data[0].delete)
            {
                fetch('pipeline_list')
                .then(res => res.json())
                .then((data) => {
                    this.setState({
                        list: data
                    });
                })
                .catch(console.log);
            }
        })
        .catch(console.log)

    }

    renderListData = () => {
        return this.state.list.map((process) => (
            <tbody>
                <tr>
                    <td className="processList">{process.ProcessName}</td>
                    <td className="processList">{process.StartTime}</td>
                    <td className="processList">{process.Command}</td>
                    <td className="processList">{process.Recording}</td>
                    <td className="processList">
                        <form name="frmDelete" onSubmit={this.handleDelete(process.ProcessId, process.Recording)}>
                        <Button variant="danger" size="lg" type="submit">Delete</Button>
                        </form>
                    </td>
                </tr>
            </tbody>
        ))
    }

    render() {
        return (<div>

                <Jumbotron className="jumboHeader">
                <center><h1 class="pipeline-title">Pipeline</h1></center>
                </Jumbotron>
                <div class="paginationClouds">{this.renderPagination()}</div>
                <Table className="pipelineList">
                {this.renderHeader()}
                {this.renderListData()}
                </Table>
                <Row className="activeProcessProgress">
                    <Col>
                        <Container>
                            <Row>
                                <Col><ProgressBar className="prgActiveProcess" animated now={this.state.activeProcessPercentage} label={this.state.activeProcessMsg}/></Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                </div>)
    }
}

export default Pipeline;
