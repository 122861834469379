import React, { Component } from 'react';
import { Button, Modal, Jumbotron } from 'react-bootstrap';

import SystemUtils from '../SystemUtils';
import CloudList from './CloudList.js';
import CloudUpload from './CloudUpload.js';

import './CloudFiles.css';

class CloudFiles extends Component {

    constructor(props) {
        super(props);

        this.state = {
            totalSpace: 1,
            freeSpace: 0,
            numClouds: 0,
            showUploadFile: false,
            errMsg: '',
            recordingAddFile: '' //leave this parameter blank for compatability
        };
    }

    componentDidMount() {
        fetch('system_stats')
        .then(res => res.json())
        .then((data) => {
            this.setState({
                totalSpace: data[0].totalSpace,
                freeSpace: data[0].freeSpace
            });
        })
        .catch(console.log)

        fetch('cloud_stats')
        .then(res => res.json())
        .then((data) => {

            this.setState({
                numClouds: data[0].numClouds
            });
        })
        .catch(console.log)
    }

    reloadPage = () => {
        this.refs.mapList.reloadPage();
    }

    openMap = file => {
        this.props.openMap(file);
    }

    showUpload = () => {
        this.setState({ showUploadFile: true });
    }

    handleSubmitUpload = () => {
        //console.log("Submitting user!");
        //console.log(this.frmAddUser.current.GetUsername());
    }

    handleCloseUpload = () => {
        this.refs.cloudUpload.Close();
        this.setState({ showUploadFile: false });
        this.refs.mapList.reloadPage();
    }

    render() {
        const totalSpace = this.state.totalSpace;
        const freeSpace = this.state.freeSpace;
        const capacity = (100.0 - ((100.0 * freeSpace) / totalSpace));
        return (<div>

                <Modal
                show={this.state.showUploadFile}
                onHide={this.handleCloseUpload}
                backdrop="static"
                centered
                >
                <Modal.Header closeButton>
                    <Modal.Title><center><h1 class="pageTitle">Upload Map</h1></center></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <CloudUpload ref="cloudUpload" recordingAddFile={this.state.recordingAddFile} handleCloseUpload={this.handleCloseUpload} />
                </Modal.Body>

                <Modal.Footer>
                </Modal.Footer>
                </Modal>

                <Jumbotron className="jumboHeader">
                <center><h1 class="pageTitle">Cloud Files</h1></center>
                <center><h2 class="pageTitle">Total Hdd Size: {SystemUtils.GetSizeString(totalSpace)} | Free Space: {SystemUtils.GetSizeString(freeSpace)} | Capacity: {capacity.toFixed(1)}%   <Button className="btnUpload" size="lg" onClick={this.showUpload}>Upload</Button></h2></center>
                </Jumbotron>
                <CloudList ref="mapList" openMap={this.openMap}
                           accessLevel={this.props.accessLevel}
                           numClouds={this.state.numClouds} />
                </div>)
    }
}

export default CloudFiles;
