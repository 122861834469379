import React, { Component } from 'react';
import { Form, Button, Col } from 'react-bootstrap';

import './Setup.css';


class Setup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dbConnectionString: '',
            dbName: '',
            dbUser: '',
            dbPass: '',
            adminUser: '',
            adminPass: ''
        };
    }

    componentDidMount() {
    }

    handleSubmit = (event) => {
        event.preventDefault();

        fetch('setup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "dbConnectionString=" + this.state.dbConnectionString + "&dbName=" + this.state.dbName + "&dbUser=" + this.state.dbUser + "&dbPass=" + this.state.dbPass + "&adminUser=" + this.state.adminUser + "&adminPass=" + this.state.adminPass
        })
        .then(res => res.json())
        .then(data =>
        {
            if (data[0].setup === 'true')
            {
                this.props.setupComplete();
            }
            else
            {
                this.props.setupMsg(data[0].msg);
            }
        })
        .catch(console.log)
    }

    handleDbConnectionStringChange = (event) => {
        this.setState({dbConnectionString: event.target.value});
    }

    handleDbNameChange = (event) => {
        this.setState({dbName: event.target.value});
    }

    handleDbUsernameChange = (event) => {
        this.setState({dbUser: event.target.value});
    }

    handleDbPassChange = (event) => {
        this.setState({dbPass: event.target.value});
    }

    handleAdminUserChange = (event) => {
        this.setState({adminUser: event.target.value});
    }

    handleAdminPassChange = (event) => {
        this.setState({adminPass: event.target.value});
    }

    render() {
        return (<div>
                <img src="LogoLight.png" alt="AutoMap Logo" width="275px"></img>
                <label class="setupLabel" htmlFor="info">This is the OrbisFuse setup page which is used to perform the initial database configuration. Please enter the credentials to the mysql database you wish to use and press save.</label>
                <Form name="frmLogin" onSubmit={this.handleSubmit}>

                    <Form.Row>
                        <Form.Group as={Col} controlId="frmDbConnectionString">
                            <Form.Label class="setupLabel">Database Connection String</Form.Label>
                            <Form.Control type="text" className="form-control" name="dbcs" onChange={this.handleDbConnectionStringChange} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="frmDatabaseName">
                            <Form.Label class="setupLabel">Database Name</Form.Label>
                            <Form.Control type="text" className="form-control" name="dbname" onChange={this.handleDbNameChange} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="frmUsername">
                            <Form.Label class="setupLabel">Database Username</Form.Label>
                            <Form.Control type="text" className="form-control" onChange={this.handleDbUsernameChange} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="frmPassword">
                            <Form.Label class="setupLabel">Database Password</Form.Label>
                            <Form.Control type="password" className="form-control" name="dbpassword" onChange={this.handleDbPassChange} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="frmAdminUsername">
                            <Form.Label class="setupLabel">Admin Username</Form.Label>
                            <Form.Control type="text" className="form-control" name="adminusername" onChange={this.handleAdminUserChange} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="frmAdminUsername">
                            <Form.Label class="setupLabel">Admin Password</Form.Label>
                            <Form.Control type="password" className="form-control" name="adminpassword" onChange={this.handleAdminPassChange} />
                        </Form.Group>
                    </Form.Row>

                    <div className="divSubmit">
                        <Button variant="danger" size="lg" type="submit">Save</Button>
                    </div>
                    <div className="divSetupErr">
                    <label class="setupLabelErr" htmlFor="setupErr">{this.props.errorMsg}</label>
                    </div>
                </Form>
            </div>);
    }
}

export default Setup;
