import React, { Component } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';

import './ProcessChange.css';

class ProcessChange extends Component {

    constructor(props) {
        super(props);

        this.state = {
            categoryRecordings: [],
            errMsg: ''
        };
    }

    onLoad = () => {
        //console.log("Modal is loaded");
    }

    render() {
        return (

            <Modal
              show={this.props.showUpdateProcess}
              onHide={this.props.hideUpdateProcess}
              onEntered={this.onLoad}
              backdrop="static"
              centered
            >
            <Modal.Header closeButton>
                <Modal.Title><center><h1 class="pageTitle">Update Category</h1></center></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div>
                <Form className="ProcessChangeForm" onSubmit={this.handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="frmName">
                            <Form.Label class="ProcessLabel">Name</Form.Label>
                            <Form.Control className="ProcessUpdate" type="text" placeholder="Name" value={this.props.process.Name} onChange={this.props.handleUpdateName}/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="frmDescription">
                            <Form.Label class="ProcessLabel">Description</Form.Label>
                            <Form.Control className="ProcessUpdate" type="text" placeholder="Description" value={this.props.process.Description} onChange={this.props.handleUpdateDescription}/>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Label class="ProcessLabel">Command</Form.Label>
                        <Form.Control className="ProcessUpdate" type="text" placeholder="Description" value={this.props.process.Command} onChange={this.props.handleUpdateCommand}/>
                    </Form.Row>

                </Form>
            </div>
            </Modal.Body>

            <Modal.Footer>
                <span className="ProcessAddErr">{this.state.errMsg}</span>
                <Button onClick={this.props.handleUpdateProcess}>Update</Button>
                <Button onClick={this.props.hideUpdateProcess}>Close</Button>
            </Modal.Footer>
            </Modal>
        )
    }
}

export default ProcessChange;
