import React, { Component } from 'react';
import { Form, Col } from 'react-bootstrap';

import './UserAdd.css';

class UserAdd extends Component {

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            errMsg: ''
        };
    }

    GetUsername = () => {
        return this.state.username
    }

    GetPassword = () => {
        return this.state.password
    }

    GetFirstname = () => {
        return this.state.firstName
    }

    GetLastname = () => {
        return this.state.lastName
    }

    GetEmail = () => {
        return this.state.email
    }

    GetPhone = () => {
        return this.state.phone
    }

    GetError = () => {
        return this.state.errMsg
    }

    handleSubmit = (event) => {
        //const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        fetch('user_add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "username=" + this.state.username + "&password=" + this.state.password + "&firstName=" + this.state.firstName + "&lastName=" + this.state.lastName + "&email=" + this.state.email + "&phone=" + this.state.phone
        })
        .then(res => res.json())
        .then(data =>
        {
            if ('true' === data[0].add)
            {
                this.setState({
                    password: '',
                    errMsg: data[0].msg
                });

                this.props.reloadUsers();
            }
            else
            {
                this.setState({
                    errMsg: data[0].msg
                });
            }
        });
    }

    handleUsernameChange = (e) => {
        this.setState({
            username: e.target.value,
            errMsg: ''
        });
    }

    handlePasswordChange = (e) => {
        this.setState({
            password: e.target.value,
            errMsg: ''
        });
    }

    handleFirstNameChange = (e) => {
        this.setState({
            firstName: e.target.value,
            errMsg: ''
        });
    }

    handleLastNameChange = (e) => {
        this.setState({
            lastName: e.target.value,
            errMsg: ''
        });
    }

    handleEmailChange = (e) => {
        this.setState({
            email: e.target.value,
            errMsg: ''
        });
    }

    handlePhoneChange = (e) => {
        this.setState({
            phone: e.target.value,
            errMsg: ''
        });
    }

    render() {
        return (
            <div>
                <Form className="UserAddForm" onSubmit={this.handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="frmUsername">
                            <Form.Label class="UserAddLabel">Username</Form.Label>
                            <Form.Control className="UserAddControl" type="text" placeholder="Username" value={this.state.username} onChange={this.handleUsernameChange}/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="frmPassword">
                            <Form.Label class="UserAddLabel">Password</Form.Label>
                            <Form.Control className="UserAddControl" type="password" placeholder="Password" value={this.state.password} onChange={this.handlePasswordChange}/>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="frmFirstName">
                            <Form.Label class="UserAddLabel">First Name</Form.Label>
                            <Form.Control className="UserAddControl" type="text" placeholder="First Name" value={this.state.firstName} onChange={this.handleFirstNameChange} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="frmLastName">
                            <Form.Label class="UserAddLabel">Last Name</Form.Label>
                            <Form.Control className="UserAddControl" type="text" placeholder="Last Name" value={this.state.lastName} onChange={this.handleLastNameChange} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="frmEmail">
                            <Form.Label class="UserAddLabel">Email Address</Form.Label>
                            <Form.Control className="UserAddControl" type="email" placeholder="Email Address" value={this.state.email} onChange={this.handleEmailChange}/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="frmPhone">
                            <Form.Label class="UserAddLabel">Phone</Form.Label>
                            <Form.Control className="UserAddControl" type="text" placeholder="Phone Number" value={this.state.phone} onChange={this.handlePhoneChange}/>
                        </Form.Group>
                    </Form.Row>

                    {/*
                    <Form.Row>
                        <Form.Group as={Col} controlId="frmSubmit" className="UserAddSubmit">
                            <Button variant="danger" size="lg" type="submit">Add</Button>
                            <Form.Label class="UserAddSubmitLabel">{this.state.errMsg}</Form.Label>
                        </Form.Group>
                    </Form.Row>
                    */}
                </Form>
            </div>
        )
    }
}

export default UserAdd;
