import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

import './Login.css';


class Login extends Component {

    constructor(props) {
        super(props);

        this.state = { //username is defined in the prop class
            password: '',
            errMsg: ''
        };
    }

    componentDidMount() {
    }

    get IsLoggedIn() {
        return this.props.isUserValid;
        //return this.state.login;
    }

    handleLogout = (event) => {
        event.preventDefault();

        fetch('logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "username=" + this.props.username
        })

        .then(res => res.json())
        .then(data =>
        {
            //console.log(data[0].login);
            //this.setState({ login: false });
            //this.props.isUserValid = false;
            this.props.isLoggedOut(data[0].username);
        })
        .catch(console.log)
    }

    handleSubmit = (event) => {
        event.preventDefault();

        fetch('login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "username=" + this.props.username + "&password=" + this.state.password
        })
        .then(res => res.json())
        .then(data =>
        {
            fetch('event_add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: "info=Login"
            })

            if (data[0].login === 'true')
            {
                this.props.isLoggedIn(data[0].username, data[0].accessLevel);
            }
            else
            {
                this.setState({ errMsg: data[0].msg });
                this.props.isLoggedOut(data[0].username, data[0].accessLevel);
            }
        })
        .catch(console.log)
    }

    handleUsernameChange = (event) => {
        this.props.username = event.target.value;
    }

    handlePasswordChange = (event) => {
        this.setState({password: event.target.value});
    }

    render() {
        const { password } = this.state;
        const username = this.props.username;

        if (this.props.isUserValid) {
            return (<div></div>);
        } else {
            return (<div>
                    <img src="LogoLight.png" alt="AutoMap Logo" width="275px"></img>
                    <form name="frmLogin" onSubmit={this.handleSubmit}>
                        <div className={'divLogin'}>
                            <label class="loginLabel" htmlFor="username">Username</label>
                            <input type="text" className="form-control" name="username" value={username} onChange={this.handleUsernameChange} />
                        </div>
                        <div className={'divPassword'}>
                            <label class="loginLabel" htmlFor="password">Password</label>
                            <input type="password" className="form-control" name="password" value={password} onChange={this.handlePasswordChange} />
                        </div>
                        <div className="divSubmit">
                            <Button className="btnLogin" size="lg" type="submit">Login</Button><span className="loginErrMsg">{this.state.errMsg}</span>
                        </div>
                    </form>
                </div>);
        }
    }
}

export default Login;
