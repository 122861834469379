import React, { Component } from 'react';
import { Jumbotron } from 'react-bootstrap';

import UserList from './UserList.js';

import './UserPage.css';

class UserPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            numUsers: 1
        };
    }

    componentDidMount() {
        fetch('user_stats')
        .then(res => res.json())
        .then((data) => {

            this.setState({
                numUsers: data[0].numUsers
            });
        })
        .catch(console.log)
    }

    reloadUsers = () => {
        fetch('user_stats')
        .then(res => res.json())
        .then((data) => {

            this.setState({
                numUsers: data[0].numUsers
            });
        })
        this.refs.userlist.reloadList();
    }

    render() {
        const numUsers = this.state.numUsers;
        return (<div>
                <Jumbotron className="jumboHeader">
                <center><h1 class="pageTitle">User List</h1></center>
                <center><h2 class="pageTitle">Total Users: {numUsers}</h2></center>
                </Jumbotron>
                <UserList reloadUsers={this.reloadUsers} numUsers={this.state.numUsers} ref="userlist" />
                </div>)
    }
}

export default UserPage;
