import React, { Component } from 'react';
import { Nav, Navbar } from 'react-bootstrap';

import Profile from './Profile.js';

import './TopBar.css';

class TopBar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mapLoaded: false
        };
    }

    isLoggedOut = (username, accessLevel) => {
        this.props.isLoggedOut(username, accessLevel);
    }

    handleOpenProfile = () => {
        this.props.showProfile();
    }

    showMapButton = () => {
        this.setState({
            mapLoaded: true
        });
    }

    renderMapButton = () => {

        if (this.state.mapLoaded)
        {
            return (
                <Nav.Link className="mapLinkOn" onClick={ this.props.showMap }>Map</Nav.Link>
            )
        }
        else
        {
            return (
                <Nav.Link className="mapLinkOff">Map</Nav.Link>
            )
        }
    }

    render() {
        if (1 === this.props.accessLevel)
        {
            return (<Navbar bg="light" expand="lg">
                <Navbar.Brand href="#home"><img src="LogoLight.png" alt="AutoMap Logo" width="200px"></img></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav.Link onClick={ this.props.showFiles } >Files</Nav.Link>
                    <Nav.Link onClick={ this.props.showPipeline } >Pipeline</Nav.Link>
                    <Nav.Link onClick={ this.props.showProcess } >Process</Nav.Link>
                    <Nav.Link onClick={ this.props.showWorkflows } >Workflows</Nav.Link>
                    <Nav.Link onClick={ this.props.showCategories } >Categories</Nav.Link>
                    <Nav.Link onClick={ this.props.showHistory } >History</Nav.Link>
                    <Nav.Link onClick={ this.props.showUsers }>Users</Nav.Link>
                    { this.renderMapButton() }
                </Navbar.Collapse>
                <Profile handleOpenProfile={this.handleOpenProfile} isLoggedOut={this.isLoggedOut} username={this.props.username} />
                </Navbar>);
        }
        else
        {
            return (<Navbar bg="light" expand="lg">
                <Navbar.Brand href="#home"><img src="LogoLight.png" alt="AutoMap Logo" width="200px"></img></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav.Link onClick={ this.props.showFiles } >Files</Nav.Link>
                    <Nav.Link onClick={ this.props.showProfile } >Pipeline</Nav.Link>
                    <Nav.Link onClick={ this.props.showProcess } >Process</Nav.Link>
                    <Nav.Link onClick={ this.props.showWorkflows } >Workflows</Nav.Link>
                    <Nav.Link onClick={ this.props.showCategories } >Categories</Nav.Link>
                    <Nav.Link onClick={ this.props.showHistory } >History</Nav.Link>
                </Navbar.Collapse>
                <Profile handleOpenProfile={this.handleOpenProfile} isLoggedOut={this.isLoggedOut} username={this.props.username} />
                </Navbar>);
        }
    }
}

export default TopBar;
