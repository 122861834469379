import React, { Component } from 'react';

import { Alert, Button, Dropdown, Image, Modal, Table, OverlayTrigger, Pagination, Tooltip } from 'react-bootstrap';

import ChangeMapSelector from './ChangeMapSelector.js';
import RecordingInfo from './RecordingInfo.js';

import SystemUtils from '../SystemUtils';
import ModalConversion from './ModalConversion.js';
import CloudUpload from './CloudUpload.js';

import './CloudList.css';

class CloudList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            startIndex: 0,
            pageSize: parseInt((window.innerHeight - 150.0) / 200), //190 is the height of each line, 150 is the height of the progress bar
            pageId: 1,
            files: [],
            categories: [],
            processes: [],
            workflows: [],
            activeFile: null,
            showConversion: false,
            conversionMsg: '',
            showAddFile: false,
            recordingAddFile: '',
            sortFilter: 'filename',
            sortOrder: 'ASC',
            renderAlert: false,
            alertMsg: '',
            alertSuccess: false
        };

        this.changeMapSelectorPopup = React.createRef()
        this.recordingInfoPopup = React.createRef()
    }

    componentDidMount() {
        fetch('cloud_list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "startIndex=" + this.state.startIndex + "&pageSize=" + this.state.pageSize
        })
        .then(res => res.json())
        .then((data) => {
          this.setState({ files: data });
        })
        .catch(console.log)

        fetch('category_list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "startIndex=-1&pageCount=0" //all categories
        })
        .then(res => res.json())
        .then((data) => {
          this.setState({ categories: data });
        })
        .catch(console.log)

        fetch('process_list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "startIndex=-1&pageCount=0" //all processes
        })
        .then(res => res.json())
        .then((data) => {
          this.setState({ processes: data });
        })
        .catch(console.log)

        fetch('workflow_list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "startIndex=-1&pageCount=0" //all processes
        })
        .then(res => res.json())
        .then((data) => {
          this.setState({ workflows: data });
        })
        .catch(console.log)
    }

    handleDelete = filename => event => {
        event.preventDefault();

        fetch('cloud_delete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "filename=" + filename
        })
        .then(res => res.json())
        .then(data =>
        {
            if ('true' === data[0].delete)
            {
                fetch('cloud_list')
                .then(res => res.json())
                .then((data) => {
                    this.setState({ files: data });
                })
                .catch(console.log);
            }
        })
        .catch(console.log)
    }

    handleMapConvertEvent = (e) => {
        var objConvert = JSON.parse(e.data);

        //console.log(objConvert);

        this.setState({
            conversionMsg: objConvert.mapName + ' ' + objConvert.msg
        });

        if (2 === objConvert.conversionState)
        {
            this.eventSource.close();

            this.setState({
                showConversion: false
            });

            fetch('cloud_list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: "startIndex=" + this.state.startIndex + "&pageSize=" + this.state.pageSize + "&sortFilter=" + this.state.sortFilter + "&sortOrder=" + this.state.sortOrder
            })
            .then(res => res.json())
            .then((data) => {
            this.setState({ files: data });
            })
            .catch(console.log)
        }
        else if (-1 === objConvert.conversionState)
        {
            this.eventSource.close();
        }
    }

    handleConvert = file => event => {
        event.preventDefault();

        if (file.converted === "true")
        {
            //open the converted map
            //console.log("Opening converted map!");
            this.props.openMap(file.filename);
        }
        else
        {
            if (file.atlas === "true")
            {
                if (file.differenceMap === "true")
                {
                    this.setState({
                        showConversion: true,
                        conversionMsg: "Converting..."
                    });

                    //convert file
                    this.eventSource = new EventSource('map_convert?mapName=' + file.filename);
                    this.eventSource.onmessage = e => this.handleMapConvertEvent(e);
                }
                else
                {
                    this.changeMapSelectorPopup.current.show(file.filename, file.category);
                }
                /*
                this.setState({
                    showConversion: true,
                    conversionMsg: "Creating Changemap..."
                });

                //convert file
                this.eventSource = new EventSource('map_change?mapName=' + file.filename);
                this.eventSource.onmessage = e => this.handleMapConvertEvent(e);
                */
            }
            else
            {
                this.setState({
                    showConversion: true,
                    conversionMsg: "Converting..."
                });

                //convert file
                this.eventSource = new EventSource('map_convert?mapName=' + file.filename);
                this.eventSource.onmessage = e => this.handleMapConvertEvent(e);
            }
        }
    }

    hideConvert = (e) => {
        this.setState({
            showConversion: false,
            conversionMsg: ""
        });
    }

    renderConvert = () => {
        return (
            <Modal
              show={this.state.showConversion}
              onHide={this.hideConvert}
              backdrop="static"
              centered
            >
            <Modal.Header closeButton>
                <Modal.Title><center><h1 class="pageTitle">Converting Map</h1></center></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <ModalConversion conversionMsg={this.state.conversionMsg} />
            </Modal.Body>

            </Modal>
        )
    }

    handleDownload = filename => event => {
        event.preventDefault();

        fetch('event_add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "info=Request download " + filename
        })
        .then(res => res.json())
        .then(data =>
        {
            var element = document.createElement('a');
            element.setAttribute('href', 'maps/' + encodeURIComponent(filename));
            element.setAttribute('download', filename);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        })
        .catch(console.log)
    }

    handleCategoryUpdate = (eventKey, event) => {
        event.preventDefault();

        var obj = eventKey.split(",");

        fetch('cloud_update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "filename=" + obj[1] + "&categoryId=" + obj[0]
        })
        .then(res => res.json())
        .then(data =>
        {

            fetch('event_add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "info=Change " + obj[1] + " category to " + obj[0]
            })
            .catch(console.log)

            fetch('cloud_list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: "startIndex=" + this.state.startIndex + "&pageSize=" + this.state.pageSize + "&sortFilter=" + this.state.sortFilter + "&sortOrder=" + this.state.sortOrder
            })
            .then(res => res.json())
            .then((data) => {
            this.setState({ files: data });
            })
            .catch(console.log)
        })
        .catch(console.log)

    }


    handleProcessSelect = (eventKey, event) => {
        event.preventDefault();

        var obj = eventKey.split(",");

        //console.log(obj);
        //console.log("Add processes to the pipeline and exec pipeline");

        fetch('pipeline_add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "processId=" + obj[0] + "&recording=" + obj[1]
        })
        .then(res => res.json())
        .then(data =>
        {

            var msg = data[0].Msg;
            var success = data[0].Success === 'true';

            if (data[0].Executor === 'true') //else executor is already running
            {
                fetch('process_executor', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    },
                    body: ""
                })
                .then(res => res.json())
                .then((data) => {

                    this.setState({
                        renderAlert: true,
                        alertMsg: msg,
                        alertSuccess: success
                    });

                    setTimeout(this.hideAlert, 3000)
                })
                .catch(console.log)
            }

        })
        .catch(console.log)
    }

    handleWorkflowSelect = (eventKey, event) => {
        event.preventDefault();

        var obj = eventKey.split(",");

        //console.log(obj);
        //console.log("Add processes to the pipeline and exec pipeline");

        fetch('pipeline_add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "workflowId=" + obj[0] + "&recording=" + obj[1]
        })
        .then(res => res.json())
        .then(data =>
        {

            var msg = data[0].Msg;
            var success = data[0].Success === 'true';

            if (data[0].Executor === 'true') //else executor is already running
            {
                fetch('process_executor', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    },
                    body: ""
                })
                .then(res => res.json())
                .then((data) => {

                    this.setState({
                        renderAlert: true,
                        alertMsg: msg,
                        alertSuccess: success
                    });

                    setTimeout(this.hideAlert, 3000)
                })
                .catch(console.log)
            }

        })
        .catch(console.log)
    }

    hideAlert = () =>
    {
        this.setState({
            renderAlert: false
        });
    }

    renderAlert = () =>
    {
        if (this.state.renderAlert)
        {
            if (this.state.alertSuccess)
            {
                return (<Alert key="success" variant="success">
                            {this.state.alertMsg}
                        </Alert>);
            }
            else
            {
                return (<Alert key="danger" variant="danger">
                            {this.state.alertMsg}
                        </Alert>);
            }
        }

    }

    getButtonText = (isConverted, isAtlas, isDifferenceMap) => {
        if (isConverted === "true")
            return "Open";
        else
        {
            if (isAtlas === "true")
                if (isDifferenceMap === "true")
                    return "Convert";
                else
                    return "Create Difference Map";
            else
                return "Convert";
        }
    }

    getButtonDisabled = uploadState => {

        if (4 === parseInt(uploadState))
        {
            return false;
        }
        else
        {
            return true;
        }
    }

    handleDownloadMap = (eventKey, event) => {
        event.preventDefault();

        //var obj = eventKey.split(",");

        //console.log("handleDownloadMap");
        //console.log(obj);
    }

    renderCategoryData = filename => {
        return this.state.categories.map((category) => (
            <Dropdown.Item as="button" eventKey={[category.categoryId, filename]} onSelect={this.handleCategoryUpdate}>{category.title}</Dropdown.Item>
        ))
    }

    renderProcessData = filename => {
        return this.state.processes.map((proc) => (
            <Dropdown.Item as="button" eventKey={[proc.ProcessId, filename]} onSelect={this.handleProcessSelect}>{proc.Name}</Dropdown.Item>
        ))
    }

    renderWorkflowData = filename => {
        return this.state.workflows.map((workflow) => (
            <Dropdown.Item as="button" eventKey={[workflow.WorkflowId, filename]} onSelect={this.handleWorkflowSelect}>{workflow.Name}</Dropdown.Item>
        ))
    }

    renderFileList = (fileArray, recording) => {
        return fileArray.map((filename) => (
            <Dropdown.Item className="downloadLink" href={'maps/' + recording + '/' + filename}>Download {filename}</Dropdown.Item>
        ))
    }

    renderDownloadData = file => {

        //console.log("console file");
        //console.log(file);
        //console.log("array files");
        //console.log(fileArray);
        //console.log(recording);

        const fileArray = file.files.split(",");
        var recording = file.filename;

        return (
            <Dropdown.Menu className="downloadDropdown">
            {this.renderFileList(fileArray, recording)};
            </Dropdown.Menu>
        )
    }

    showRecordingInfo = (filename, comment) => {;
        this.recordingInfoPopup.current.show(filename, comment);
    }

    AddFile = (filename) => {;
        this.setState({ showAddFile: true, recordingAddFile: filename });
    }

    handleCloseUpload = () => {
        this.setState({ showAddFile: false });
    }

    renderListData = () => {
        if (1 === this.props.accessLevel)
        {
            return this.state.files.map((file) => (
                <tbody>
                    <tr>
                        <td>
                            { this.renderPreview(file) }
                        </td>
                        <td className="cloudList">
                            <Dropdown>
                            <Dropdown.Toggle size="lg" variant="success" id="filename">
                                {file.filename}
                            </Dropdown.Toggle>

                            { this.renderDownloadData(file) }
                            </Dropdown>
                        </td>
                        <td className="cloudList">
                            {SystemUtils.GetSizeString(file.size)}
                        </td>
                        <td className="cloudList">
                            {file.uploadDate}
                        </td>
                        <td className="cloudList">
                            <Dropdown>
                            <Dropdown.Toggle size="lg" variant="success" id="category">
                                {file.category}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {this.renderCategoryData(file.filename)}
                            </Dropdown.Menu>
                            </Dropdown>
                        </td>
                        <td className="cloudList">
                            <Button size="lg" onClick={() => this.AddFile(file.filename)}>Add File</Button>
                        </td>
                        <td className="cloudList">
                            <Button size="lg" onClick={() => this.showRecordingInfo(file.filename, file.comment)}>{file.comment}</Button>
                        </td>
                        <td className="cloudList">
                            <Dropdown>
                            <Dropdown.Toggle size="lg" variant="success" id="process">
                                Add workflow to queue
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {this.renderWorkflowData(file.filename)}
                            </Dropdown.Menu>
                            </Dropdown>
                        </td>
                        <td className="cloudList">
                            <form name="frmConvert" onSubmit={this.handleConvert(file)}>
                            <Button variant="danger" size="lg" type="submit" disabled={this.getButtonDisabled(file.uploadState)}>{this.getButtonText(file.converted, file.atlas, file.differenceMap)}</Button><input type="hidden" name="filename" value={file.filename} /></form>
                        </td>
                        <td className="cloudList">
                            <form name="frmDelete" onSubmit={this.handleDelete(file.filename)}>
                            <Button variant="danger" size="lg" type="submit">Delete</Button><input type="hidden" name="filename" value={file.filename} /></form>
                        </td>
                    </tr>
                </tbody>
            ))
        }
        else
        {
            return this.state.files.map((file) => (
                <tbody>
                    <tr>
                        <td>
                            { this.renderPreview(file) }
                        </td>
                        <td className="cloudList">
                            <Dropdown>
                            <Dropdown.Toggle size="lg" variant="success" id="filename">
                                {file.filename}
                            </Dropdown.Toggle>

                            { this.renderDownloadData(file) }
                            </Dropdown>
                        </td>
                        <td className="cloudList">
                            {SystemUtils.GetSizeString(file.size)}
                        </td>
                        <td className="cloudList">
                            {file.uploadDate}
                        </td>
                        <td className="cloudList">
                            <Dropdown>
                            <Dropdown.Toggle size="lg" variant="success" id="category">
                                {file.category}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                { this.renderCategoryData(file.filename) }
                            </Dropdown.Menu>
                            </Dropdown>
                        </td>
                        <td className="cloudList">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" placement="left"><h3>{file.comment}</h3></Tooltip>}>
                            <span className="d-inline-block">
                                <Button disabled size="lg" style={{ pointerEvents: 'none' }}>
                                {file.desc}
                                </Button>
                            </span>
                            </OverlayTrigger>
                        </td>
                        <td className="cloudList">
                            <form name="frmConvert" onSubmit={this.handleConvert(file)} disabled={this.getButtonDisabled(file.uploadState)}>
                            <Button variant="danger" size="lg" type="submit">{this.getButtonText(file.converted, file.atlas, file.differenceMap)}</Button><input type="hidden" name="filename" value={file.filename} /></form>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            ))
        }
    }

    renderPagination = () => {
        let numPages = Math.ceil((this.props.numClouds) / this.state.pageSize);
        let items = [];
        let active = this.state.pageId;

        var iStart = active - 5;
        var iFinish = active + 5;

        if (iStart < 1)
            iStart = 1;

        if (iFinish > numPages)
            iFinish = numPages;

        //for (let number = 1; number <=numPages; number++)
        for (let number = iStart; number <=iFinish; number++)
        {
            items.push(
                <Pagination.Item className="pageClouds" key={number} active={number === active}>
                {number}
                </Pagination.Item>,
            );
        }

        return (
            <Pagination onClick={this.loadPage}>{items}</Pagination>
        )
    }

    createChangeMap = () => {
        fetch('create_changemap', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "startIndex=" + this.state.startIndex + "&pageSize=" + this.state.pageSize
        })
        .then(res => res.json())
        .then((data) => {
        this.setState({ files: data });
        })
        .catch(console.log)
    }


    reloadPage = () => {
        fetch('cloud_list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "startIndex=" + this.state.startIndex + "&pageSize=" + this.state.pageSize + "&sortFilter=" + this.state.sortFilter + "&sortOrder=" + this.state.sortOrder
        })
        .then(res => res.json())
        .then((data) => {
        this.setState({ files: data });
        })
        .catch(console.log)
    }

    reloadPage = (filter, order) => {
        fetch('cloud_list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "startIndex=" + this.state.startIndex + "&pageSize=" + this.state.pageSize + "&sortFilter=" + filter + "&sortOrder=" + order
        })
        .then(res => res.json())
        .then((data) => {
        this.setState({ files: data });
        })
        .catch(console.log)
    }

    loadPage = e => {
        if (typeof e.target.text !== "undefined")
        {
            let newPageId = parseInt(e.target.text);
            let newStartIndex = (newPageId-1) * this.state.pageSize;

            this.setState({
                pageId: newPageId,
                startIndex: newStartIndex
            });

            fetch('cloud_list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: "startIndex=" + newStartIndex + "&pageSize=" + this.state.pageSize + "&sortFilter=" + this.state.sortFilter + "&sortOrder=" + this.state.sortOrder
            })
            .then(res => res.json())
            .then((data) => {
            this.setState({ files: data });
            })
            .catch(console.log)
        }
    }

    sortByFilename = () => {

        var sortOrder = "ASC";

        if (this.state.sortOrder === "ASC")
            sortOrder = "DESC";

        this.setState({
            sortFilter: "filename",
            sortOrder: sortOrder
        });

        this.reloadPage("filename", sortOrder);
    }

    renderFilenameSortButton()
    {
        if (this.state.sortFilter === "filename")
        {
            if (this.state.sortOrder === "ASC")
            {
                return (
                    <Image src="sortDown.png" width="24" />
                )
            }
            else
            {
                return (
                    <Image src="sortUp.png" width="24" />
                )
            }
        }
        else
            return (
                <Image src="sortNone.png" width="24" />
            )
    }

    sortBySize = () => {

        var sortOrder = "ASC";

        if (this.state.sortOrder === "ASC")
            sortOrder = "DESC";

        this.setState({
            sortFilter: "size",
            sortOrder: sortOrder
        });

        this.reloadPage("size", sortOrder);
    }

    renderSizeSortButton()
    {
        if (this.state.sortFilter === "size")
        {
            if (this.state.sortOrder === "ASC")
            {
                return (
                    <Image src="sortDown.png" width="24" />
                )
            }
            else
            {
                return (
                    <Image src="sortUp.png" width="24" />
                )
            }
        }
        else
            return (
                <Image src="sortNone.png" width="24" />
            )
    }

    sortByUploadDate = () => {

        var sortOrder = "ASC";

        if (this.state.sortOrder === "ASC")
            sortOrder = "DESC";

        this.setState({
            sortFilter: "uploadDate",
            sortOrder: sortOrder
        });

        this.reloadPage("uploadDate", sortOrder);
    }

    renderUploadDateSortButton()
    {
        if (this.state.sortFilter === "uploadDate")
        {
            if (this.state.sortOrder === "ASC")
            {
                return (
                    <Image src="sortDown.png" width="24" />
                )
            }
            else
            {
                return (
                    <Image src="sortUp.png" width="24" />
                )
            }
        }
        else
            return (
                <Image src="sortNone.png" width="24" />
            )
    }

    sortByCategory = () => {

        var sortOrder = "ASC";

        if (this.state.sortOrder === "ASC")
            sortOrder = "DESC";

        this.setState({
            sortFilter: "category",
            sortOrder: sortOrder
        });

        this.reloadPage("category", sortOrder);
    }

    renderCategorySortButton()
    {
        if (this.state.sortFilter === "category")
        {
            if (this.state.sortOrder === "ASC")
            {
                return (
                    <Image src="sortDown.png" width="24" />
                )
            }
            else
            {
                return (
                    <Image src="sortUp.png" width="24" />
                )
            }
        }
        else
            return (
                <Image src="sortNone.png" width="24" />
            )
    }

    renderHeader = () => {
        if (1 === this.props.accessLevel)
        {
            return (
                <thead>
                    <tr>
                        <th><h3>Preview</h3></th>
                        <th onClick={this.sortByFilename}><h3>File { this.renderFilenameSortButton() }</h3></th>
                        <th onClick={this.sortBySize}><h3>Size</h3></th>
                        <th onClick={this.sortByUploadDate}><h3>Upload Date { this.renderUploadDateSortButton() }</h3></th>
                        <th onClick={this.sortByCategory}><h3>Category { this.renderCategorySortButton() }</h3></th>
                        <th></th>
                        <th><h3>Comment</h3></th>
                        <th><h3>Process</h3></th>
                        <th><h3>View Maps</h3></th>
                        <th><h3>Delete</h3></th>
                    </tr>
                </thead>
            )
        }
        else
        {
            return (
                <thead>
                    <tr>
                        <th><h3>Preview</h3></th>
                        <th onClick={this.sortByFilename}><h3>File { this.renderFilenameSortButton() }</h3></th>
                        <th onClick={this.sortBySize}><h3>Size</h3></th>
                        <th onClick={this.sortByUploadDate}><h3>Upload Date { this.renderUploadDateSortButton() }</h3></th>
                        <th onClick={this.sortByCategory}><h3>Category { this.renderCategorySortButton() }</h3></th>
                        <th><h3>Status</h3></th>
                        <th><h3>Process</h3></th>
                        <th><h3>View Maps</h3></th>
                        <th></th>
                    </tr>
                </thead>
            )
        }
    }

    renderPreview = (file) => {
        if ('true' === file.preview)
            return <img src={'maps/' + file.filename + '/preview.png'} width="128" alt={'maps/' + file.filename + '/preview.png does not exist'}/>;
        else
            return <img src={'noPreview.png'} width="128" alt={'maps/' + file.filename + '/preview.png does not exist'}/>;
    }

    render() {
        return (
            <div>

            {this.renderConvert()}
            <Modal
            show={this.state.showAddFile}
            onHide={this.handleCloseUpload}
            backdrop="static"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title><center><h1 class="pageTitle">Upload Map</h1></center></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <CloudUpload ref="cloudUpload" recordingAddFile={this.state.recordingAddFile} handleCloseUpload={this.handleCloseUpload} />
            </Modal.Body>

            <Modal.Footer>
            </Modal.Footer>
            </Modal>

            <div class="paginationClouds">{this.renderPagination()}</div>
            <Table className="cloudListTable">
            {this.renderHeader()}
            {this.renderListData()}
            <ChangeMapSelector ref={this.changeMapSelectorPopup} reloadCloudList={this.reloadPage} />
            <RecordingInfo ref={this.recordingInfoPopup} reloadCloudList={this.reloadPage} />
            </Table>
            {this.renderAlert()}
            </div>
        )
    }
}

export default CloudList;
