import React, { Component } from 'react';
import './Profile.css';

import Button from 'react-bootstrap/Button';

class Profile extends Component {

    handleLogout = (event) => {
        event.preventDefault();

        fetch('logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "username=" + this.props.username
        })

        .then(res => res.json())
        .then(data =>
        {
            this.props.isLoggedOut(data[0].username, data[0].accessLevel);
        })
        .catch(console.log)
    }

    handleProfile = (event) => {
        this.props.handleOpenProfile();
    }

    render() {
        return (<div class="introWidth">
                    <form class="form-inline" name="frmLogout" onSubmit={this.handleLogout}><h2 class="title">Welcome</h2> <h2 class="userProfile" onClick={this.handleProfile}>{this.props.username}</h2><Button className="btnProfile" size="sm" type="submit">Logout</Button></form>
                </div>);
    }
}

export default Profile;

