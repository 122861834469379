import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';

import './CategoryList.css';

class CategoryList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            categories: []
        };
    }

    componentDidMount() {
        fetch('category_list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "startIndex=0&pageSize=10"
        })
        .then(res => res.json())
        .then((data) => {
          this.setState({ categories: data });
        })
        .catch(console.log)
    }

    reloadList = (startIndex, pageSize) => {
        fetch('category_list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "startIndex=" + startIndex + "&pageSize=" + pageSize
        })
        .then(res => res.json())
        .then((data) => {
          this.setState({ categories: data });
        })
        .catch(console.log)
    }

    handleDelete = categoryId => event => {
        event.preventDefault();

        //const name = ReactDOM.findDOMNode(this._nameInput).value;
        //console.log(event.target.name);
        //console.log(event.target.value);
        fetch('category_delete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
            body: "categoryId=" + categoryId
        })
        .then(res => res.json())
        .then(data =>
        {
            if ('true' === data[0].delete)
            {
                this.props.reloadCategories();
            }
        })
        .catch(console.log)
    }

    showUpdateCategory = (category) => {
        this.props.showUpdateCategory(category);
    }

    render() {
        const categories = this.state.categories;
        if (1 === this.props.accessLevel)
        {
            return (
                categories.map((category) => (
                <div class="card">
                    <div class="CategoryBody">
                    <form class="form-inline" name="frmLogout" onSubmit={this.handleDelete(category.categoryId)}>
                    <span class="CategoryMarker" style={{'background-color' : '#' + category.colour}}></span>
                    <h2 class="CategoryItem" onClick={() => this.showUpdateCategory(category)}>{category.title}</h2>
                    <Button variant="danger" size="lg" type="submit">Delete</Button><input type="hidden" name="categoryId" value={category.categoryId} /></form>
                    </div>
                </div>
                ))
            )
        }
        else
        {
            return (
                categories.map((category) => (
                <div class="card">
                    <div class="CategoryBody">
                    <form class="form-inline" name="frmLogout"  onSubmit={this.handleDelete(category.categoryId)}>
                    <span class="CategoryMarker" style={{'background-color' : '#' + category.colour}}></span>
                    <h2 class="CategoryItem" onClick={() => this.showUpdateCategory(category)}>{category.title}</h2>
                    </form>
                    </div>
                </div>
                ))
            )
        }
    }
}

export default CategoryList;
