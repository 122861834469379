import React, { Component } from 'react';
import { Modal, Table, Button } from 'react-bootstrap';

import './RecordingInfo.css';

class RecordingInfo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            files: [],
            originalComment: '',
            recordingComment: '',
            originalName: '',
            recordingName: '',
            showPopup: false,
            errMsg: ''
        };

        this.handleRecordingInfoChange = this.handleRecordingInfoChange.bind(this);
        this.handleRecordingCommentChange = this.handleRecordingCommentChange.bind(this);
    }

    show = (filename, comment) => {

        //console.log("show recording file info " + filename + " - " + comment);

        this.setState({
            originalComment: comment,
            recordingComment: comment,
            originalName: filename,
            recordingName: filename
        });

        fetch('recording_files', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "recording=" + filename
        })
        .then(res => res.json())
        .then((data) => {
            this.setState({
                showPopup: true,
                files: data
            });
        })
        .catch(console.log)

    }

    handleClose = () => {
        this.setState({ showPopup: false });
        this.props.reloadCloudList();
    }

    handleUploadNewFile = () => {
    }

    handleDelete = filename => event => {
        event.preventDefault();

        fetch('recording_file_delete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "recording=" + this.state.originalName + "&filename=" + filename
        })
        .then(res => res.json())
        .then(data =>
        {
            if ('true' === data[0].recordingDeleted)
            {
                this.handleClose();
            }
            else
            {
                if ('true' === data[0].fileDeleted)
                {
                    fetch('recording_files', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    },
                    body: "recording=" + this.state.originalName
                })
                .then(res => res.json())
                .then((data) => {
                    this.setState({
                        files: data
                    });
                })
                .catch(console.log)
                }
            }
        })
        .catch(console.log)
    }

    handleUpdateRecordingName = () => {

        fetch('recording_file_update', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: "originalName=" + this.state.originalName + "&newName=" + this.state.recordingName + "&comment=" + this.state.recordingComment
            })
            .then(res => res.json())
            .then((data) => {
                this.handleClose();
            })
            .catch(console.log)
    }

    renderListData = () => {

        return this.state.files.map((file) => (
            <tbody>
                <tr>
                    <td>
                        <a href={"maps/" + this.state.recordingName + "/" + file.filename}>{file.filename}</a>
                    </td>
                    <td>
                        {file.size}
                    </td>
                    <td>
                        {file.uploadDate}
                    </td>
                    <td>
                        {file.errMsg}
                    </td>
                    <td>
                        <form class="form-inline" name="frmDelete" onSubmit={this.handleDelete(file.filename)}>
                        <Button variant="danger" size="lg" type="submit">Delete</Button>
                        <input type="hidden" name="filename" value={file.filename} />
                        </form>
                    </td>
                </tr>
            </tbody>
        ))
    }

    handleRecordingInfoChange(event) {

        this.setState({
            recordingName: event.target.value
        });

    }

    handleRecordingCommentChange(event) {

        this.setState({
            recordingComment: event.target.value
        });

    }

    render() {
        return (
            <div>

            <Modal
              dialogClassName="recordingInfoModal"
              show={this.state.showPopup}
              onHide={this.handleClose}
              backdrop="static"
              centered
            >
            <Modal.Header closeButton>
                <Modal.Title><center><h1 class="pageTitle">Recording Info</h1></center></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Table className="fileTable">
                <thead>
                    <tr>
                        <th class="recordingFile"><h3>File</h3></th>
                        <th class="recordingFileSize"><h3>File Size</h3></th>
                        <th class="recordingLastUpdated"><h3>Last Updated</h3></th>
                        <th class="recordingError"><h3>Error</h3></th>
                        <th class="recordingFileAbsorbingColumn"></th>
                    </tr>
                </thead>
                {this.renderListData()}
                </Table>
            </Modal.Body>

            <Modal.Footer>
                <input name="txtRecordingComment" type="text" value={this.state.recordingComment} onChange={this.handleRecordingCommentChange} />
                <input name="txtRecordingName" type="text" value={this.state.recordingName} onChange={this.handleRecordingInfoChange} />
                <Button onClick={this.handleUpdateRecordingName}>Save</Button>
            </Modal.Footer>
            </Modal>

            </div>
        )
    }
}

export default RecordingInfo;
