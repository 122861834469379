import React, { Component } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';

import './Endpoint.css';

class Endpoint extends Component {

    constructor(props) {
        super(props);

        this.state = {
            endpoint: '',
            endpointEnabled: false,
            errMsg: ''
        };
    }

    componentDidMount() {
        fetch('settings')
        .then(res => res.json())
        .then((data) => {

            //console.log('componentDidMount.EndpointEnabled');
            //console.log(data[0].EndpointEnabled);
            //console.log(('true' === data[0].EndpointEnabled));

            this.setState({ endpoint: data[0].Endpoint, endpointEnabled: ('true' === data[0].EndpointEnabled) });
        })
        .catch(console.log)
    }

    reloadList = () => {
        fetch('settings')
        .then(res => res.json())
        .then((data) => {

            //console.log('reloadList.EndpointEnabled');
            //console.log(data[0].EndpointEnabled);
            //console.log(('true' === data[0].EndpointEnabled));

            this.setState({ endpoint: data[0].Endpoint, endpointEnabled: ('true' === data[0].EndpointEnabled) });
        })
        .catch(console.log)
    }

    handleSubmit = (event) => {
        //const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        fetch('settings_update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "endpoint=" + this.state.endpoint + "&endpointEnabled=" + this.state.endpointEnabled
        })
        .then(res => res.json())
        .then(data =>
        {
            if ('true' === data[0].success)
            {
                this.props.reloadSettings();
            }
            else
            {
                this.setState({
                    endpoint: data[0].msg
                });
            }
        })
    }

    handleEndpointChange = (e) => {
        //console.log('Endpoint Enabled');
        //console.log(e.target);

        this.setState({
            endpoint: e.target.value
        });
    }

    handleEndpointEnableChange = (e) => {
        //console.log('handleEndpointEnableChange');
        //console.log(e.target);

        this.setState({
            endpointEnabled: e.target.checked
        });

    }

    render() {
        return (
            <div>
                <Form className="EndpointForm" onSubmit={this.handleSubmit}>
                    <Row>
                        <Col sm="10">
                            <Form.Control className="EndpointControl" type="text" placeholder="Endpoint" value={this.state.endpoint} onChange={this.handleEndpointChange}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Check inline className="chkEndpoint" type="checkbox" label="Enable" checked={this.state.endpointEnabled} onChange={this.handleEndpointEnableChange} />
                            <Button variant="danger" size="lg" type="submit">Save</Button>
                            <Form.Label class="EndpointSubmitLabel">{this.state.errMsg}</Form.Label>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
}

export default Endpoint;
