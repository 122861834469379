import React, { Component } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';

import './CategoryChange.css';

class CategoryChange extends Component {

    constructor(props) {
        super(props);

        this.state = {
            categoryRecordings: [],
            errMsg: ''
        };
    }

    onLoad = () => {
        //console.log("Modal is loaded");
        if (this.props.category.title.length > 0)
        {
            fetch('cloud_list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: "category=" + this.props.category.title + "&sortFilter=category&sortOrder=ASC&recording=null"
            })
            .then(res => res.json())
            .then((data) => {

                //console.log("onLoad response");

                if (0 === data.length)
                {
                    this.props.category.baselineRecording = "";
                }
                else if (1 === data.length)
                {
                    this.props.category.baselineRecording = data[0].filename;
                }

                this.setState({ categoryRecordings: data });

            })
            .catch(console.log)
        }
        else
        {
            this.setState({ categoryRecordings: [] });
        }
    }

    handleUpdateCategory = (e) => {
        //console.log("handleUpdateCategory");

        e.preventDefault();
        e.stopPropagation();

        fetch('category_update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "categoryId=" + this.props.category.categoryId + "&title=" + this.props.category.title + "&colour=" + this.props.category.colour + "&baselineRecording=" + this.props.category.baselineRecording
        })
        .then(res => res.json())
        .then(data =>
        {
            if ('true' === data[0].update)
            {
                this.setState({
                    errMsg: data[0].msg
                });

                //this.props.reloadProcesses();
                //this.props.hideUpdateCategory();
            }
            else
            {
                this.setState({
                    errMsg: data[0].msg
                });
            }
        })
    }

    renderBaselineRecordingData = () => {

        return this.state.categoryRecordings.map((recordings) => (
            <option value={recordings.filename}>{recordings.filename}</option>
        ))
    }

    render() {
        return (

            <Modal
              show={this.props.updateCategoryVisible}
              onHide={this.props.hideUpdateCategory}
              onEntered={this.onLoad}
              backdrop="static"
              centered
            >
            <Modal.Header closeButton>
                <Modal.Title><center><h1 class="pageTitle">Update Category</h1></center></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div>
                <Form className="ProcessAddForm" onSubmit={this.handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="frmTitle">
                            <Form.Label class="CategoryAddLabel">Title</Form.Label>
                            <Form.Control className="CategoryAddControl" type="text" placeholder="title" value={this.props.category.title} onChange={this.props.handleUpdateTitleChange}/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="frmColour">
                            <Form.Label class="CategoryAddLabel">Colour</Form.Label>
                            <Form.Control className="CategoryAddControl" type="text" placeholder="colour" value={this.props.category.colour} onChange={this.props.handleUpdateColourChange}/>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Label class="CategoryAddLabel">Baseline Recording</Form.Label>
                        <Form.Control as="select" size="sm" value={this.props.category.baselineRecording} onChange={this.props.handleUpdateBaselineRecordingChange}>
                            { this.renderBaselineRecordingData() }
                        </Form.Control>
                    </Form.Row>

                </Form>
            </div>
            </Modal.Body>

            <Modal.Footer>
                <span className="userAddErr">{this.state.errMsg}</span>
                <Button onClick={this.handleUpdateCategory}>Update</Button>
                <Button onClick={this.props.hideUpdateCategory}>Close</Button>
            </Modal.Footer>
            </Modal>
        )
    }
}

export default CategoryChange;
