import React, { Component } from 'react';
import { Form, Col } from 'react-bootstrap';

import './CategoryAdd.css';

class CategoryAdd extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title: '',
            colour: '',
            baselineRecording: '',
            errMsg: ''
        };
    }

    GetTitle = () => {
        return this.state.title
    }

    GetColour = () => {
        return this.state.colour
    }

    GetBaselineRecording = () => {
        return this.state.baselineRecording
    }

    GetError = () => {
        return this.state.error
    }

    handleTitleChange = (e) => {
        this.setState({
            title: e.target.value,
            errMsg: ''
        });
    }

    handleColourChange = (e) => {
        this.setState({
            colour: e.target.value,
            errMsg: ''
        });
    }

    handleBaselineRecordingChange = (e) => {
        this.setState({
            baselineRecording: e.target.value,
            errMsg: ''
        });
    }

    render() {
        return (
            <div>
                <Form className="CategoryAddForm" onSubmit={this.handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="frmTitle">
                            <Form.Label class="CategoryAddLabel">Title</Form.Label>
                            <Form.Control className="CategoryAddControl" type="text" placeholder="Category Title" value={this.state.title} onChange={this.handleTitleChange}/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="frmColour">
                            <Form.Label class="CategoryAddLabel">Colour</Form.Label>
                            <Form.Control className="CategoryAddControl" type="text" placeholder="Category Colour" value={this.state.colour} onChange={this.handleColourChange} />
                        </Form.Group>
                    </Form.Row>
                </Form>
            </div>
        )
    }
}

export default CategoryAdd;
